import api from '@/utils/api'
import {
    Box, Loader, Paper, Text, useMantineTheme, alpha
} from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { AccountTypes, AnyService, GoogleServices, MetaServices } from '../../../../types/global'
import { Check, CircleCheck } from 'tabler-icons-react'

const pollingURIs = {
    "Google": {
        "Google Search Console": "/manual/status/auto/google/search-console"
    },
    "Meta": {
        "Meta Ads": "/manual/status/auto/meta/ads",
        "Facebook Pages": "/manual/stats/auto/meta/pages",
        "Instagram": "/manual/stats/auto/meta/instagram"
    }
} as Record<AccountTypes, Record<AnyService, string>>

const headerTextOverwrites = {
    "Amazon Ads": {
        success: "Click the button below to accept the access request",
        pending: "Waiting for the agency to send you an invite..."
    }
} as Record<AnyService, Record<"success" | "pending", string>>


export const useManualGrantStatus = ({ service, accountType, inviteID, resources, readOnlyGranted }: { service: AnyService, accountType: AccountTypes, inviteID: string, resources: (string | { instagramAccountID: string, pageID: string })[], readOnlyGranted?: boolean }) => {
    const [isGranted, setIsGranted] = useState(!!readOnlyGranted)
    const [grantStatus, setGrantStatus] = useState<Record<string, boolean>>({})

    const shouldRunGrantStatusQuery = () => {
        return !readOnlyGranted && (pollingURIs[accountType] && pollingURIs[accountType][service])
    }
    const updateGrantStatus = () => {
        if (shouldRunGrantStatusQuery()) {
            api(pollingURIs[accountType][service], {
                inviteID,
                resources
            }).then((res) => {
                console.log("GRANT STATUS:", res.data)
                if (!res.error) {
                    setIsGranted(!Object.keys(res.data).filter((o) => !res.data[o]).length)
                    return setGrantStatus(res.data)
                }
                return setGrantStatus({})
            })
        }
    }

    useEffect(() => {
        setIsGranted(!!readOnlyGranted)
    }, [readOnlyGranted])

    useEffect(() => {
        if (Object.keys(grantStatus).length && !Object.keys(grantStatus).filter((g) => !grantStatus[g]).length) {
            // return setIsGranted(true)
            return;
        }
        return setIsGranted(false)
    }, [grantStatus])

    // useEffect(() => {
    //     setParentGranted(isGranted)
    // }, [isGranted])

    useEffect(() => {
        if (!shouldRunGrantStatusQuery()) return;
        const newGrantStatus = { ...grantStatus }
        resources?.forEach((r) => {
            let key;
            if (typeof r === "string") {
                key = r
            } else {
                key = r.instagramAccountID
            }
            if (!newGrantStatus[key]) {
                newGrantStatus[key] = false
            }
        })

        Object.keys(newGrantStatus).forEach((s) => {
            if (!resources.includes(s)) delete newGrantStatus[s]
        })

        setGrantStatus(newGrantStatus)

        if (!resources?.length || readOnlyGranted) {
            return
        }
        updateGrantStatus()
        const checker = setInterval(() => {
            updateGrantStatus()
        }, 15000)

        return () => clearInterval(checker)
    }, [resources])

    return { isGranted, grantStatus }
}








export default function ManualAccessGrantsListener({ service, accountType, inviteID, resources, readOnlyGranted, setParentGranted }: { service: AnyService, accountType: AccountTypes, inviteID: string, resources: string[], readOnlyGranted?: boolean, setParentGranted: (val: boolean) => void }) {
    const { grantStatus, isGranted } = useManualGrantStatus({
        service,
        accountType,
        inviteID,
        resources,
        readOnlyGranted
    })
    const theme = useMantineTheme()

    if (!pollingURIs[accountType] || !pollingURIs[accountType][service]) return null;

    return (
        <Paper bg={alpha(theme.colors[isGranted ? "green" : "orange"][6], .1)} p={12} style={{ borderRadius: 0, gap: 12 }} className="flex aic">
            <Box bg={isGranted ? "green" : "orange"} style={{ width: 10, height: 10, borderRadius: "50%" }}></Box>
            <div className='flex aic jcsb' style={{ flex: 1 }}>
                <div>
                    <Text c={isGranted ? "green" : 'orange'} fz="sm" fw={500} >{isGranted ? (headerTextOverwrites[service]?.success || "Manual access granted successfully") : (headerTextOverwrites[service]?.pending || "Waiting for access to be granted...")}</Text>
                    {Object.keys(grantStatus).map((g) => <Box className='flex aic' style={{ gap: 8 }}>
                        {grantStatus[g]
                            ? <CircleCheck color='var(--mantine-color-green-6)' size={12} />
                            : <Loader size={10} color={'orange'} />
                        }
                        <Text fz="xs" c={grantStatus[g] ? "green" : 'orange'} >{g}</Text>
                    </Box>)}
                </div>
                {Object.keys(grantStatus).length
                    ? <Text c={isGranted ? "green" : 'orange'} fz="sm" fw={500}>{Object.keys(grantStatus).filter((g) => grantStatus[g as string]).length}/{Object.keys(grantStatus).length}</Text>
                    : null
                }
            </div>
        </Paper>
    )
}
