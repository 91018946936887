import { Box, Divider, Text } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import Link from 'next/link'
import React from 'react'
import { BrandFacebook, BrandLinkedin, BrandTwitter, BrandYoutube } from 'tabler-icons-react'
import { AnyService, GoogleServices, MetaServices } from '../../../types/global'
import { Logo } from './Logo'

const LinkCol = ({ title, links }: { title: string, links: Array<{ label: string, value: string, icon?: React.ReactElement }> }) => {
    return (
        <Box mb={20} miw={130} bg={"#f9f9f9"} p="0" style={{ borderRadius: 15, flex: 1 }}>
            <Text fw={700} fz="md">{title}</Text>
            <Box className='flex fdc' style={{ gap: 5 }} mt={8}>
                {links.map(l => (
                    <Link href={l.value} className="flex aic" style={{ gap: 5 }}>
                        {l.icon
                            ? <>{React.cloneElement(l.icon, { size: 18, color: "var(--mantine-color-dimmed)" })}</>
                            : null
                        }
                        <Text lineClamp={1} fz="sm" fw={400} c="dimmed">{l.label}</Text>
                    </Link>
                ))}
            </Box>
        </Box>
    )
}

export default function Footer() {
    const mobile = useMediaQuery('(max-width: 500px)')
    return (
        <Box component={"footer"} className='flex fdc' style={{ background: "#f9f9f9", width: "100%", padding: mobile ? 20 : 40 }}>
            <Box className='flex jcsb' style={{ flexWrap: "wrap", gap: 10 }}>
                <LinkCol
                    title='Integrations'
                    links={(["Meta Ads", "Facebook Pages", "Facebook Product Catalog", "Google Ads", "Google Analytics", "Google Business Profile", "Google Merchant Center", "Google Search Console", "Google Tag Manager"] as Array<AnyService>).map((service) => ({
                        label: service,
                        value: `/service/${service}`
                    }))}
                />
                <LinkCol
                    title='Contact Us'
                    links={[
                        { label: "Chat with us", value: "/contact" },
                        { label: "Create a ticket", value: "/contact" },
                    ]}
                />
                <LinkCol
                    title='Legal'
                    links={[
                        { label: "Privacy Policy", value: "/legal/privacy" },
                        { label: "Terms of Service", value: "/legal/terms" },
                    ]}
                />
                <LinkCol
                    title='Docs'
                    links={[
                        { label: "Zapier", value: "/docs/zapier" }
                    ]}
                />
                <LinkCol
                    title='Socials'
                    links={[
                        { label: "YouTube", value: "https://www.youtube.com/channel/UC5OTe2___a1OgQsgJKjIqIQ", icon: <BrandYoutube /> },
                        { label: "Facebook", value: "https://facebook.com", icon: <BrandFacebook /> },
                        { label: "X", value: "https://twitter.com", icon: <BrandTwitter /> },
                        { label: "LinkedIn", value: "https://linkedin.com", icon: <BrandLinkedin /> },
                    ]}
                />

            </Box>
            <Divider my={35} pt={15} opacity={.5} />
            <Box className='flex aic jcsb' style={{ flexWrap: "wrap" }}>
                <Logo width={200} />
                <Text fz={"xs"} mt={20} ta="center" c="dimmed">© Copyright of Volpax {new Date().getFullYear()}
                    <br />
                </Text>
            </Box>
            {/* <div className='flex fdc' style={{ gap: 5, marginTop: 20, color: "#000", }}>
                <Text fz={"sm"} c={"dimmed"} fw={600}>Legal</Text>
                <Text fz={"sm"} c="dimmed">
                    <Link style={{ color: "inherit" }} href={"/legal/privacy"}>Privacy Policy</Link>
                </Text>
                <Text fz={"sm"} c="dimmed">
                    <Link style={{ color: "inherit" }} href={"/legal/terms"}>Terms & Conditions</Link>
                </Text>
            </div> */}
        </Box>
    )
}
