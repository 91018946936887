import api from '@/utils/api'
import { ThemeContext } from '@/utils/context/Theme.context'
import { MultiSelect, Paper, Popover, Select, Skeleton, Text, ThemeIcon, Tooltip } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AnyService, Theme } from '../../../../types/global'
import Error from '../Error'
import { Check, Minus } from 'tabler-icons-react'
import { GrantsStatus } from './GrantsContainer'
import { InviteContext } from '@/utils/context/Invite.context'

type SelectorProps = {
    businessName: string,
    inviteID: string,
    serviceConfig: { [key in string]: string[] },
    setServiceConfigs: (newConfig: { [key in string]: string[] | null }) => void,
    service: AnyService,
    apiRoute: string,
    setAccounts?: (a: Array<{ account_id: string, id: string }>) => void,
    __facebookPagesForInstagramValidation?: Array<{ id: string, account_id: string }>,
}

type ConflictingInstagramPageLink = {
    page: { name: string, id: string },
    instagramAccount: { name: string, id: string }
}

export const Selector = ({
    service,
    businessName,
    inviteID,
    setServiceConfigs,
    serviceConfig,
    apiRoute,
    setAccounts: forwardAccountsToParent,
    __facebookPagesForInstagramValidation,
}: SelectorProps) => {
    console.log(serviceConfig)
    const theme = useContext(ThemeContext)
    const vertical = useMediaQuery("(max-width: 460px)")
    const invite = useContext(InviteContext)
    const [selectedAccounts, setSelctedAccounts] = useState<string[] | null>(serviceConfig[service] || [])
    const [instagramAccountsThatRequirePageGrants, setInstagramAccountsThatRequirePageGrants] = useState<ConflictingInstagramPageLink[]>([])
    const [accounts, setAccounts] = useState<Array<{
        account_id: string, id: string, page?: string
    }> | undefined>(undefined)
    const [error, setError] = useState('')

    console.log(accounts)
    useEffect(() => {
        if (selectedAccounts?.length !== (serviceConfig[service] || [])?.length) {
            setServiceConfigs({
                [service]: selectedAccounts
            })
        }
    }, [selectedAccounts])

    useEffect(() => {
        if (!forwardAccountsToParent) return;
        if (!accounts) return;
        forwardAccountsToParent(accounts)
    }, [accounts])

    useEffect(() => {
        if (!apiRoute) return
        api(apiRoute, {
            inviteID: inviteID
        }).then((res) => {
            if (res.error) {
                return setError(res.msg)
            }
            setAccounts(res.data)
        })
    }, [])

    useEffect(() => {
        if (service !== "Instagram" || (invite.requestedAccounts.Meta["Facebook Pages"]?.granted)) return setInstagramAccountsThatRequirePageGrants([]);
        const conflictingPages: ConflictingInstagramPageLink[] = []

        selectedAccounts?.map((instagramAccountID) => {
            const accountDetails = accounts?.find((a) => a.id === instagramAccountID)
            if (!accountDetails) return;
            if (!serviceConfig["Facebook Pages" as AnyService]?.includes(accountDetails.page as string)) {
                conflictingPages.push({
                    instagramAccount: {
                        id: instagramAccountID,
                        name: accountDetails.account_id
                    },
                    page: {
                        id: accountDetails.page as string,
                        name: __facebookPagesForInstagramValidation?.find((a) => a.id === accountDetails.page)?.account_id as string
                    }
                })
            }
        })
        setInstagramAccountsThatRequirePageGrants(conflictingPages)
    }, [selectedAccounts, __facebookPagesForInstagramValidation, accounts, serviceConfig, invite])

    useEffect(() => {
        if (service !== "Instagram") return;
        console.log("instagramAccountsThatRequirePageGrants", instagramAccountsThatRequirePageGrants)
    }, [instagramAccountsThatRequirePageGrants])

    if (error) {
        // return <Error mt={vertical ? 10 : 0}>{error}</Error>
    }
    if (!accounts && !error) {
        return (
            <Skeleton mt={vertical ? 10 : 0} w={200} h={40} />
        )
    }
    console.log(accounts)
    return (
        <Popover offset={40} opened={!!instagramAccountsThatRequirePageGrants.length} position="right" withArrow withinPortal>
            <Popover.Target>
                <Tooltip offset={{ mainAxis: 40 }} withArrow opened={true} maw={300} multiline position="right" disabled={!error} label={error} color="red">
                    <MultiSelect error={!!instagramAccountsThatRequirePageGrants.length} radius={10} maw={205} mt={vertical ? 10 : 0} color={theme.color || "dark"} placeholder={error ? "No accounts found" : (!accounts?.length ? "No accounts found" : "Select accounts")} key={service} clearable value={selectedAccounts || undefined} onChange={(e) => {
                        setSelctedAccounts(e)
                    }} data={accounts?.map((acc) => ({ value: acc.id, label: `${acc.account_id || businessName} (${acc.id})` })) || []} />
                </Tooltip>
            </Popover.Target>
            <Popover.Dropdown maw={200} p={"xs"} bg={"red"}>
                <Text fz='sm' c="#fff">To grant Instagram access, you must select the following Facebook pages: <strong>{instagramAccountsThatRequirePageGrants.map((a) => a.page.name).join(", ")}</strong></Text>
            </Popover.Dropdown>
        </Popover>
    )
}

export const GrantedChip = ({ skipped }: { skipped?: boolean }) => {
    const theme = useContext(ThemeContext)
    const vertical = useMediaQuery("(max-width: 460px)")
    return (
        <Paper h={"fit-content"} mt={vertical ? 5 : 0} w={"fit-content"} radius={100} p={5} px={10} className="flex aic" style={{ gap: 5 }} withBorder>
            <ThemeIcon variant={"light"} size="sm" color={skipped ? "gray" : (theme.color || "green")} style={{ borderRadius: 100 }}>
                {skipped
                    ? <Minus size={20} />
                    : <Check size={20} />
                }

            </ThemeIcon>
            <Text fw={500} fz="sm">{skipped ? "Skipped" : "Granted"}</Text>
        </Paper>
    )
}