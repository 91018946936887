import { ServiceGrantsContainer } from '@/components/invite/GrantsContainer'
import { Logo } from '@/components/Logo'
import { PlatformsContext } from '@/utils/context/Platforms.context'
import { ThemeContext } from '@/utils/context/Theme.context'
import { roleValueToLabel } from '@/static/roles'
import { getGrantedStatus, useInviteGrantStatus } from '@/utils/helpers/useGranted'
import { Box, Button, Paper, Skeleton, Text, useMantineColorScheme, Image as MantineImage, Collapse, Transition, Tooltip } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import Image from 'next/image'
import Link from 'next/link'
import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { InfoCircle, Key, X } from 'tabler-icons-react'
import { AccountTypes, AnyService, Invite, RequestedAccounts, Theme } from '../../../../../types/global'
import { SubdomainContext } from '@/utils/context/Subdomain.context'
import { InviteContext } from '@/utils/context/Invite.context'
import { ThankYouScreen } from '@/components/invite/ThankYouScreen'
import { useRouter } from 'next/router'
import PlatformAuthButton from '@/components/invite/PlatformAuthButton'
import api from '@/utils/api'
import { InviteClientContext } from '@/utils/context/InviteClient.context'
import { MetaPlatformWideGuide } from '@/components/invite/PlatformWideGuides'
import AccountsContainer from '@/components/invite/AccountsContainer'
import getButtonThemeProps from '@/utils/helpers/getButtonThemeProps'
import { useGrantStatus } from '@/hooks/useGrantStatus'

// const MetaOauthCallback = (id: string, sub?: string) => window.location.replace(
//     encodeURI(
//         `https://www.facebook.com/v18.0/dialog/oauth?client_id=${314827657731889}&redirect_uri=${`${process.env.NODE_ENV === "production" ? "https://www.agencyaccess.co" : "http://localhost:3001"}/i/oauth/facebook`}&state=${JSON.stringify([id, sub])}&scope=${"business_management catalog_management ads_management"}`
//     )
// )

// const GoogleOAuthCallback = (id: string, sub?: string) => window.location.replace(
//     encodeURI(
//         `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=offline&scope=${("https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/analytics.manage.users https://www.googleapis.com/auth/analytics.edit https://www.googleapis.com/auth/tagmanager.manage.users https://www.googleapis.com/auth/tagmanager.readonly https://www.googleapis.com/auth/content https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/webmasters")}&include_granted_scopes=true&state=${JSON.stringify([id, sub])}&redirect_uri=${(`${process.env.NODE_ENV === "production" ? "https://agencyaccess.co" : "http://localhost:3001"}/i/oauth/google`)}&client_id=${("919394265473-g8k6kgi77k5tdofb4it437mko3au8mk2.apps.googleusercontent.com")}&prompt=consent`
//     )
// )

const serviceConfigAPIRoutes = {
    "Meta": {
        "Meta Ads": "/providers/facebook/ads/accounts",
        "Facebook Pages": "/providers/facebook/pages/accounts",
        "Instagram": "/providers/facebook/business/accounts"
    },
    "Google": {
        "Google Ads": "/providers/google/ads/customers",
        "Google Ads MCC": "/providers/google/ads/customers",
        "Google Analytics": "/providers/google/analytics/accounts",
        "Google Tag Manager": "/providers/google/tags-manager/accounts",
        "Google Merchant Center": "/providers/google/merchant/accounts",
        "Google Business Profile": "/providers/google/business-profile/accounts",
        "Google Search Console": "/providers/google/search-console/resources",
        "Google DV360": "/providers/google/dv360/"
    }
} as Record<AccountTypes, Record<AnyService, string>>

const grantURIs = {
    "Meta": "/grant/facebook",
    "Google": "/grant/google"
} as { [key in AccountTypes]: string }

export const Account = ({ account, id, preview, setFocusedPlatform }: { account: AccountTypes, id: string, preview?: RequestedAccounts | boolean, setFocusedPlatform: (p: AccountTypes) => void }) => {
    const invite = useContext(InviteContext)
    const platforms = useContext(PlatformsContext)
    const [granted, setGranted] = useState(false)
    const vertical = useMediaQuery("(max-width: 460px)")
    const { grantsStatus } = useGrantStatus(invite, account, !!preview)
    // Some services are manual and don't require OAuth authentication. We'll hide the login button if all services are auth-less
    const [requiresAuth, setRequiresAuth] = useState<undefined | boolean>(undefined)
    useEffect(() => {
        if (!platforms || preview) return;


        setRequiresAuth(!!Object.keys(invite.requestedAccounts[account]).filter((reqService) => {
            const accountPlatforms = platforms.find((p) => p.platform === account)
            const serviceRequiresAuth = accountPlatforms?.services.find((s) => s.name === reqService && s.requiresAuth)
            return serviceRequiresAuth
        }).length)
    }, [platforms, invite, preview, account, account])

    useEffect(() => {
        if (preview) return
        setGranted(getGrantedStatus(invite, account))
    }, [invite, account])

    if (!Object.keys(grantsStatus).filter((g) => !grantsStatus[g as AnyService]?.granted).length && !preview) {
        const nextUngratned = Object.keys(invite.requestedAccounts).filter(a => Object.keys(invite.requestedAccounts[a as AccountTypes]).filter((s) => !invite.requestedAccounts[a as AccountTypes][s as AnyService].granted).length)[0] as AccountTypes | undefined
        return (
            <Box py={20} className='flex aic fdc' w="100%">
                <Paper w={50} h={50} bg={"gray.0"} style={{ borderRadius: 100 }} shadow='sm' className='flex aic jcc'>
                    <Image alt={`${account} logo`} src={`/images/logos/${account.toLowerCase().replaceAll(" ", "_")}.png`} width={33} height={33} style={{ width: 33, height: 33, objectFit: "contain" }} />
                </Paper>
                <Text mt={5} fw={600} fz="lg">Access granted</Text>
                <Text ta="center" maw={300} fz="sm" c="dimmed">You granted access to <Text span fz="sm" c="dimmed" fw={500}>{Object.keys(grantsStatus).join(", ")}</Text></Text>
                {nextUngratned
                    ? <Button onClick={() => setFocusedPlatform(nextUngratned)} variant="default" mt={10} leftSection={<Image width={18} height={18} style={{ objectFit: "contain" }} alt={`${nextUngratned} logo`} src={`/images/logos/${nextUngratned.toLowerCase().replaceAll(" ", "_")}.png`} />}>Continue to {nextUngratned}</Button>
                    : null
                }

            </Box>
        )
    }
    return (
        <Box p={0}>
            <div className={`flex ${!vertical ? "aic" : ""} jcsb`} style={{ gap: 15, flexDirection: vertical ? "column" : "row" }}>
                <div className='flex aic' style={{ gap: 15 }}>
                    <Image alt={account} width={30} height={30} style={{ borderRadius: 50, objectFit: "contain", backgroundColor: "#fff", padding: 5 }} src={`/images/logos/${account.toLowerCase().replaceAll(" ", "_")}.png`} />
                    <div>
                        <Text fz={"md"} fw={500}>{account} Accounts</Text>
                    </div>
                </div>
                {!granted && requiresAuth
                    ? <PlatformAuthButton account={account} />
                    : null
                }

            </div>
            {!preview
                ? <>
                    {account === "Meta"
                        ? <MetaPlatformWideGuide invite={invite} />
                        // @ts-ignore
                        : <ServiceGrantsContainer
                            account={account}
                            setGranted={(granted: boolean) => setGranted(granted)}
                            id={id}
                            // details={details}
                            isLogged={!!(invite.creds?.[account]?.access_token)}
                            services={invite.requestedAccounts[account]}
                            grantURI={grantURIs[account]}
                            apiRoutes={serviceConfigAPIRoutes[account]}
                        />
                    }
                </>
                : <>
                    {/* @ts-ignore */}
                    {preview[account]
                        // @ts-ignore
                        ? <ServiceGrantsContainer
                            isPreview={true}
                            account={account}
                            setGranted={() => void 0}
                            id={""}
                            // @ts-ignore
                            services={preview[account]}

                        />
                        : null
                    }
                </>
            }
        </Box>
    )
}

const Tutorial = () => {
    const theme = useContext(ThemeContext)
    const [expanded, setExpanded] = useState(true)
    const mobile = useMediaQuery("(max-width: 460px)")
    const vertical = useMediaQuery("(max-width: 550px)")
    const steps = [
        {
            text: "Login to the requested platforms using the sign in buttons above.",
            img: "/images/invite/step1.png"
        },
        {
            text: "Select the resource/s you'd like to share for each service.",
            img: "/images/invite/step2.png"
        },
        {
            text: "Once you've made your selections, click grant access. It's as easy as that!",
            img: "/images/invite/step3.png"
        }
    ] as {
        img?: string,
        text: string
    }[]

    return (
        <Box style={{ width: "100%", marginTop: 30, padding: mobile ? 20 : 0, paddingTop: 0 }} w={"100%"} maw={550}>
            <div className='flex aic jcsb'>
                <div className='flex aic' style={{ gap: 8 }}>
                    <InfoCircle size={20} />
                    <Text fw={600} fz="xl">How it works</Text>
                </div>
                <Button size={"compact-sm"} variant={"subtle"} onClick={() => setExpanded(!expanded)} color={theme.color === "dark" && theme.theme === "dark" ? "#fff" : (theme.color || "dark")}>{expanded ? "Hide" : "Show"}</Button>
            </div>
            <Collapse in={expanded}>
                <Paper w={"100%"} radius={10} mt={15} bg={theme.theme === "dark" ? "#171923" : undefined}>
                    <Box p="md" className='flex' style={{ width: "100%", flexDirection: vertical ? "column" : "row", gap: vertical ? 40 : 10 }}>
                        {steps.map((s, i) => {
                            return (
                                <div style={{ flex: 1 }}>
                                    <MantineImage width={"100%"} mah={vertical ? 150 : undefined} style={{ aspectRatio: "4 / 3", borderRadius: 10, objectFit: "contain" }} src={s.img} />
                                    {/* <Skeleton mah={vertical ? 150 : undefined} w={"100%"} style={{ aspectRatio: "4 / 3" }} /> */}
                                    <div style={{ marginTop: 10 }}>
                                        <Text fw={500}>Step {i + 1}</Text>
                                        <Text fz={"sm"} mt={3}>{s.text}</Text>
                                    </div>
                                </div>
                            )
                        })}
                    </Box>
                </Paper>
            </Collapse>
        </Box >
    )
}



export const InviteContainer = ({ children, preview, theme: overwriteTheme }: { children: (theme: Theme) => any, preview?: boolean, theme?: Theme }) => {
    const theme = useContext(ThemeContext)
    const { setColorScheme, colorScheme } = useMantineColorScheme();
    const mobile = useMediaQuery("(max-width: 460px)")
    useEffect(() => {
        if (theme.theme === "dark") {
            setColorScheme("dark")
        } else {
            setColorScheme("light")
        }
    }, [theme])
    return (
        <div style={{ width: "100%", position: "relative", minHeight: preview ? "100%" : "100vh", padding: `40px ${mobile ? 0 : 20}px`, background: colorScheme === "dark" ? "#1a202c" : "#f9f9f9" }} className="flex fdc aic">
            <Link href={theme.logo ? "#" : "https://www.agencyaccess.co"} className='flex aic jcc'>
                {theme.logo
                    ? <img src={theme.logo} style={{
                        objectFit: "contain",
                        width: theme.logoWidthPercentage || 200
                    }} />
                    : <Logo width={200} dark={colorScheme === "dark"} />
                }
            </Link>
            <div style={{ marginTop: 20, width: "100%" }} className="flex aic jcc fdc">
                {children(theme)}
                {/* <Tutorial /> */}
            </div>
            {/* <div style={{ paddingBottom: 30, marginTop: 30 }} className="flex aic jcc fdc">
                <Text c="dimmed" fz={"sm"}>Powered by</Text>
                <Link href={"https://agencyaccess.co"}>
                    <div style={{ filter: "grayscale(100%)" }}>
                        <Logo dark={theme.theme === "dark"} width={150} />
                    </div>
                </Link>
            </div> */}
        </div >
    )
}

export const InviteBodyContainer = ({ children }: PropsWithChildren) => {
    const theme = useContext(ThemeContext)
    const mobile = useMediaQuery("(max-width: 460px)")

    return <Paper style={{ overflow: "hidden" }} radius={mobile ? 0 : "xl"} mt={20} bg={theme.theme === "dark" ? "#171923" : undefined} w={"100%"} maw={550} px={0} py={30} className='flex aic jcc fdc'>
        {children}
    </Paper>
}

const Platform = ({ acc, focusedAccount, switchAccount }: { acc: AccountTypes, focusedAccount: AccountTypes, switchAccount: (a: AccountTypes) => void }) => {
    const invite = useContext(InviteContext)
    const [isGranted, setIsGranted] = useState(false)
    const theme = useContext(ThemeContext)
    const isFocused = focusedAccount === acc

    useEffect(() => {
        console.log(invite.requestedAccounts)
        setIsGranted(!Object.keys(invite.requestedAccounts[acc]).filter((s) => !invite.requestedAccounts[acc][s as AnyService].granted && invite.requestedAccounts[acc][s as AnyService].requested).length)
    }, [invite])
    return (
        <>
            <Box onClick={() => switchAccount(acc as AccountTypes)} style={{ borderRadius: 10, gap: 5, transition: "all .1s" }} p={"xs"} bg={isFocused ? (theme.color || "dark") : (theme.theme === "dark" ? "dark.7" : "gray.0")} className='flex aic cp'>
                <Box bg={isGranted ? "green.6" : "orange.6"} style={{ borderRadius: 100 }} w={10} h={10}></Box>
                <Image alt={acc} src={`/images/logos/${acc.replaceAll(" ", "_").toLowerCase()}.png`} style={{ objectFit: "contain", marginLeft: 2 }} width={16} height={16} />
                <Text fw={600} fz="sm" lh={1} c={isFocused || theme.theme === "dark" ? "#fff" : "#000"}>{acc}</Text>
            </Box>
        </>
    )
}

export const AccountSelectorBar = ({
    focusedAccount,
    setFocusedAccount,
    accountGrantContainerVis,
    setAccountGrantContainerVis
}: {
    focusedAccount: AccountTypes,
    setFocusedAccount: (a: AccountTypes) => void,
    accountGrantContainerVis: boolean,
    setAccountGrantContainerVis: (v: boolean) => void
}) => {
    const invite = useContext(InviteContext)
    const theme = useContext(ThemeContext)
    const [tooltipShown, setTooltipShown] = useState(true)

    const switchAccount = useCallback((newAccount: AccountTypes) => {
        setAccountGrantContainerVis(false)
        setTimeout(() => {
            setFocusedAccount(newAccount)
            setAccountGrantContainerVis(true)
        }, 200)
    }, [])

    useEffect(() => {
        if (tooltipShown) {
            setTimeout(() => {
                setTooltipShown(false)
            }, 5000)
        }
    }, [tooltipShown])

    return (
        <Box w={"100%"} className='flex aic horizontalOverflow' style={{ gap: 5, overflowX: "auto" }} pb={5}>
            {Object.keys(invite.requestedAccounts).sort().map((acc, i) => {
                return <Box>
                    <Tooltip transitionProps={{ transition: "pop", duration: 300 }} position="left" style={{ zIndex: 999999999999999 }} withArrow opened={!i && tooltipShown} label={
                        <Box style={{ position: "relative" }}>
                            <Box className='flex aic' style={{ gap: 5 }}>
                                <Key size={15} />
                                <Text fw={600} fz="sm">It's time to grant access</Text>
                            </Box>
                            <Text fz="xs" className='flex aic' style={{ gap: 8 }}>
                                <Box bg={"orange.6"} style={{ borderRadius: 100 }} w={10} h={10}></Box>
                                Indicates access has not yet been granted
                            </Text>
                            <Text fz="xs" className='flex aic' style={{ gap: 8 }}>
                                <Box bg={"green.6"} style={{ borderRadius: 100 }} w={10} h={10}></Box>
                                Indicates access has been granted successfully
                            </Text>
                        </Box>}>
                        <div>
                            <Platform acc={acc as AccountTypes} focusedAccount={focusedAccount} switchAccount={switchAccount} key={i} />
                        </div>
                    </Tooltip>
                </Box>
            })}
        </Box>
    )
}

export default function ClientInvite() {
    const router = useRouter()
    const invite = useContext(InviteContext)
    const client = useContext(InviteClientContext)
    const granted = useInviteGrantStatus(invite.id, invite)
    const theme = useContext(ThemeContext)
    const mobile = useMediaQuery("(max-width: 460px)")

    useEffect(() => {
        if (granted && invite.intakeForm?.requested && !invite.intakeForm?.compelte) {
            router.push(`/i/${invite.id}/intake`)
        }
    }, [granted])

    if (granted && invite.intakeForm?.requested && !invite.intakeForm?.compelte) {
        return null
    }

    return (
        <>
            {granted && (invite.intakeForm?.requested ? invite.intakeForm.compelte : true)
                ? <ThankYouScreen invite={invite} />
                : <InviteBodyContainer>
                    <Box px={mobile ? 15 : 30}>
                        <Text ta="center" mt={0} fw={700}><Text component={"span"} fz={25} fw={700}>👋 Hey {client.name}</Text> <br /> <Text fw={500} component={"span"} fz="md">{theme.displayName || invite.agency.name} has requested access to the following accounts</Text></Text>
                    </Box>
                    {/* <Text ta="center" mt={0} fw={700}><Text component={"span"} fz={25} fw={700}>{theme.displayName || invite.agency.name}</Text> <br /> <Text fw={500} component={"span"} fz="md">has requested access to your accounts</Text></Text> */}
                    {/* <Divider w={"100%"} my={15} /> */}
                    {/* <Box px={mobile ? 15 : 30} mt={accountSelectHeaderShown ? 35 : 0} w={"100%"} style={{ overflow: "hidden" }} >
                        {accountSelectHeaderShown
                            ? <AccountSelectorBar accountGrantContainerVis={accountGrantContainerVis} setAccountGrantContainerVis={setAccountGrantContainerVis} focusedAccount={focusedAccount} setFocusedAccount={setFocusedAccount} />
                            : null
                        }
                        <Transition transition={"pop"} duration={200} mounted={accountGrantContainerVis}>
                            {(styles) => (
                                <Box mt={accountSelectHeaderShown ? 20 : 35} className="flex fdc" style={{ width: "100%", gap: 15, borderRadius: 10, ...styles }}>
                                    <Account id={invite.id} account={focusedAccount} />
                                </Box>
                            )}
                        </Transition>
                    </Box> */}
                    <AccountsContainer granted={granted} requestedAccounts={invite.requestedAccounts}>
                        {(account, setFocusedAccount) => <Account setFocusedPlatform={setFocusedAccount} id={invite.id} account={account} />}
                    </AccountsContainer>
                </InviteBodyContainer>
            }
            {!granted
                ? <Tutorial />
                : null
            }

            <Box mt={20}>
                <Text fw={600} fz={"sm"} c="dimmed">Need help? Email <Text target="_blank" href={`mailto:${invite.agency.email}`} span fw={600} fz="sm" c="blue" className='cp' component={Link}>{invite.agency.email}</Text></Text>
            </Box>
            <div style={{ paddingBottom: 30, marginTop: 30 }} className="flex aic jcc fdc">


                <Text c="dimmed" fz={"sm"}>Powered by</Text>
                <Link href={"https://agencyaccess.co"}>
                    <div style={{ filter: "grayscale(100%)" }}>
                        <Logo dark={theme.theme === "dark"} width={150} />
                    </div>
                </Link>
                <Link href={"https://agencyaccess.co"}>
                    <Text fz="xs" c="dimmed" style={{ textDecoration: "underline" }}>Create a free onboarding guide for your agency</Text>
                </Link>
            </div>
        </>
    )
}