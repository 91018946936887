import { Button } from '@mantine/core'
import Image from 'next/image'
import React, { useContext } from 'react'
import { AccountTypes } from '../../../../types/global'
import { InviteContext } from '@/utils/context/Invite.context'
import { SubdomainContext } from '@/utils/context/Subdomain.context'
import { UserContext } from '@/utils/context/User.context'

const MetaOauthCallback = (id: string, sub?: string, test?: boolean) => window.location.replace(
    encodeURI(
        // ${"business_management catalog_management pages_show_list ads_read public_profile"}
        `https://www.facebook.com/v18.0/dialog/oauth?client_id=${314827657731889}&redirect_uri=${`${process.env.NODE_ENV === "production" ? "https://www.agencyaccess.co" : "http://localhost:3001"}/i/oauth/facebook`}&state=${JSON.stringify([id, sub])}&scope=${test ? `${"business_management catalog_management pages_show_list ads_read public_profile"}` : `${"business_management email pages_show_list public_profile"}`}`
    )
)

const GoogleOAuthCallback = (id: string, sub?: string) => window.location.replace(
    encodeURI(
        `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=offline&scope=${("https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/analytics.manage.users https://www.googleapis.com/auth/analytics.edit https://www.googleapis.com/auth/tagmanager.manage.users https://www.googleapis.com/auth/tagmanager.readonly https://www.googleapis.com/auth/content https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/webmasters")}&include_granted_scopes=true&state=${JSON.stringify([id, sub])}&redirect_uri=${(`${process.env.NODE_ENV === "production" ? "https://agencyaccess.co" : "http://localhost:3001"}/i/oauth/google`)}&client_id=${("919394265473-g8k6kgi77k5tdofb4it437mko3au8mk2.apps.googleusercontent.com")}&prompt=consent`
    )
)

export default function PlatformAuthButton({ account, preview }: { account: AccountTypes, preview?: boolean }) {
    const invite = useContext(InviteContext)
    const subdomain = useContext(SubdomainContext)
    console.log("Rendering for", account)
    return (
        <Button w={"fit-content"} variant={"default"} leftSection={<Image alt={account} width={25} height={25} style={{ borderRadius: 50, objectFit: "contain", backgroundColor: "#fff", padding: 5 }} src={`/images/logos/${account?.toLowerCase().replaceAll(" ", "_")}.png`} />}
            onClick={() => {
                if (preview) return
                switch (account) {
                    case "Google":
                        GoogleOAuthCallback(invite.id, subdomain)
                        break
                    case "Meta":
                        MetaOauthCallback(invite.id, subdomain, invite.agency.email === "ethan@agencyaccess.co")
                        break
                }
            }}>{!preview && invite.creds?.[account]?.access_token ? `Switch Account` : `Sign in with ${account}`}</Button>
    )
}
