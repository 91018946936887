// Import the functions you need from the SDKs you need
import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, initializeFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCMxpBcxR8V3QHund_Eju2q0qKKev6s3Xo",
    authDomain: "agencyaccess-bff0f.firebaseapp.com",
    projectId: "agencyaccess-bff0f",
    storageBucket: "agencyaccess-bff0f.appspot.com",
    messagingSenderId: "919394265473",
    appId: "1:919394265473:web:b77b82dae9ed778a9fe080",
    measurementId: "G-HR948CPLCR"
} as FirebaseOptions;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = initializeFirestore(app, {}, process.env.NODE_ENV !== "production" ? "(default)" : "(default)")
export const auth = getAuth()
// const analytics = getAnalytics(app);