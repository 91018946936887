import { useContext, useState } from "react";
import { AccountTypes, AnyService, BaseServiceConfig, Invite, Platform } from "../../../../types/global";
import { PlatformsContext } from "../context/Platforms.context";
import { GrantsStatus } from "@/components/invite/GrantsContainer";
import api from "../api";

const grantURIs = {
    "Meta": "/grant/facebook",
    "Google": "/grant/google"
} as { [key in AccountTypes]: string }

export const useGrantRequest = () => {
    const platforms = useContext(PlatformsContext)
    const [loading, setLoading] = useState(false)
    const [errorList, setErrorList] = useState<string[]>([])
    const [error, setError] = useState("")

    const sendGrantRequest: (inv: Invite, sc: BaseServiceConfig, p: AccountTypes) => Promise<GrantsStatus> = async (invite, serviceConfigs, account) => new Promise((resolve, reject) => {
        setLoading(true)
        setError("")
        setErrorList([])

        const newServiceCongigs: BaseServiceConfig = {};

        (Object.keys(serviceConfigs) as Array<AnyService>).map((service: AnyService) => {
            if (invite.requestedAccounts[account][service]?.granted || !!platforms?.find((p) => p.platform === account)?.services?.find((s) => s.name === service)?.manual) {
                return
            }
            newServiceCongigs[service] = serviceConfigs[service]
        });

        const grantURI = grantURIs[account]

        api(grantURI, {
            serviceConfig: newServiceCongigs,
            inviteID: invite.id
        }).then((res) => {
            if (res.error) {
                setLoading(false)
                reject(res.msg)
                setError(res.msg)
                return
            }

            const responseData = res.data as {
                grantsStatus: GrantsStatus,
                errors: Array<string>
            }
            setLoading(false)
            if (responseData.errors?.length) {
                setErrorList(responseData.errors)
            }
            resolve(responseData.grantsStatus)
            return responseData.grantsStatus
        })
    })

    return {
        sendGrantRequest,
        loading,
        setLoading,
        error,
        setError,
        errorList,
        setErrorList
    }
}