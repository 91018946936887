import { useMantineTheme } from "@mantine/core"
import { useRouter } from "next/router"
import { CSSProperties } from "react"
import { ExternalLink } from "tabler-icons-react"

export const InlinePageLink = ({ children, href, newTab, style, color }: { children: string, href: string, newTab?: boolean, style?: CSSProperties, color?: string }) => {
    const router = useRouter()
    const theme = useMantineTheme()

    return <span onClick={() => newTab ? window.open(href) : router.push(href)} className="cp" style={{ borderBottom: `1px dashed ${color || theme.colors.blue[6]}`, color: color || theme.colors.blue[6], ...style }}>
        {children}
        {newTab
            ? <ExternalLink size={15} color={color || theme.colors.blue[6]} style={{ right: 0, marginLeft: 5 }} />
            : null
        }

    </span>
}