import CopyBox from "@/components/CopyBox";
import { InlinePageLink } from "@/components/InlineLink";
import { Button, Indicator, Text, TextInput, Box, Paper, Highlight, Popover, Image, Divider, CopyButton } from "@mantine/core";
import React, { ReactElement, useRef, useState } from "react";
import { Check, Copy, ExternalLink, Settings, UserPlus } from "tabler-icons-react";
import { AnyService, GoogleServices, Invite, InviteCreds, MetaServices, PinterestServices, Theme, TwitterServices } from "../../../types/global";
import Link from "next/link";

export type InstructionProps = {
    resource?: string[], accessLevel?: string, inviteIdentifier?: string, creds: InviteCreds, form?: {
        label: string,
        id: string,
        value: string | string[],
        placeholder?: string
    }[],
    agencyName?: string
    inviteData?: Invite,
    oAuthProviderButton?: React.ReactElement,
    assetSelector?: React.ReactElement,
    theme: Theme,
    setFormValue?: (id: string, val: string | string[]) => void
}
export type Instruction = {
    index: number,
    title: string,
    description: (props: InstructionProps) => ReactElement,
    rightSection?: (props: InstructionProps) => ReactElement,
    image?: string,
    decisionPoint?: {
        id: string,
        choices: Array<{
            label: string,
            value: string
        }>
    },
    condition?: {
        type: string,
        value: string
    },
    // decisionPoint?: string, // If this step is tied to a decision point, we'll decide to render it based on this
    decisionContext?: Array<string>
}

const InlineInput = ({ value, setValue, placeholder }: { value: string, placeholder: string, setValue: (v: string) => void }) => {
    const ref = useRef()
    const [focused, setFocused] = useState(false)
    return <Popover withArrow shadow="sm" radius={10}>
        <Popover.Target>
            <Highlight fw={500} p={2} px={5} bg={!value ? "red.1" : "blue.1"} color={!value ? "red.1" : "blue.1"} style={{ borderRadius: 5 }} span fz="sm" className="cp" highlight={value || placeholder}>{value || placeholder}</Highlight>
        </Popover.Target>
        <Popover.Dropdown p={0}>
            <TextInput autoFocus value={value} onChange={(e) => setValue(e.target.value)} styles={{ input: { border: "none" } }} placeholder="Enter value" />
        </Popover.Dropdown>
    </Popover>
}

export default {
    "Google Search Console": [
        {
            index: 0,
            title: "Sign in with Google",
            description: ({ oAuthProviderButton }) => <>
                <Text mb={10} fz="sm">Click the <strong>Sign in with Google</strong> button</Text>
            </>,
            rightSection: ({ oAuthProviderButton }) => <>{oAuthProviderButton}</>
        },
        {
            index: 1,
            title: "Select domains",
            description: () => <Text fz="sm">
                Select the domain you'd like to give access to from the dropdown above
            </Text>,
            rightSection: ({ assetSelector }) => <>{assetSelector}</>
        },
        {
            index: 2,
            title: "Navigate to the console",
            description: ({ resource }: InstructionProps) => <Text fz="sm">
                Navigate to <InlinePageLink newTab href={`https://search.google.com/search-console/users${resource ? `?resource_id=${resource}` : ""}`}>Google Seach Console</InlinePageLink>. It should look something like this.
            </Text>,
            image: "1.png"
        },
        {
            index: 3,
            title: "Grant Access",
            description: ({ inviteIdentifier, accessLevel }) => (
                <>
                    <Text fz="sm">Click the <strong>Add User</strong> button.</Text>
                    <Text fz="sm">In the email field, type:</Text>
                    <CopyBox mt={5} value={inviteIdentifier as string} />

                    <Text mt={5} fz="sm">In the permission field, type <strong>{accessLevel}</strong></Text>
                    <br />
                    <Text fz="sm">Click <strong>Add</strong> and wait for the indicator above to change from <Text fz="sm" span fw={600} c="orange">Waiting</Text> to <Text fz="sm" span fw={600} c="blue">Successful</Text></Text>

                </>
            )
        }
    ],
    "YouTube Studio": [
        {
            index: 0,
            title: "Navigate to YouTube studio",
            description: () => <Text fz="sm">Navigate to <InlinePageLink newTab href="https://studio.youtube.com">YouTube Studio</InlinePageLink>. Make sure you are logged in to the correct Google Account and are viewing the correct channel. It should look something like this.</Text>,
            image: "1.png"
        },
        {
            index: 1,
            title: "Open Settings",
            description: () => <Text fz="sm">Click the <Text fz="sm" fw={600} span style={{ gap: 5 }}>{<Settings size={15} />} Settings</Text> button in the bottom left corner of your screen</Text>
        },
        {
            index: 2,
            title: "Determine your account type",
            description: () => <Text fz="sm">Select the apropriate option to continue</Text>,
            decisionPoint: {
                id: "account_type",
                choices: [
                    { value: "personal", label: "I can manage my permissions here" },
                    { value: "brand", label: `I see "You're currently managing channel permissions through a Brand account."` }
                ]
            }
        },

        // This guide is rendered for users with a brand account who manage their permissions off site
        {
            index: 3,
            title: "Navigate to brand account settings",
            description: () => <Text fz="sm">Click the <strong>Manage Permissions</strong> button</Text>,
            image: "4.png",
            condition: {
                type: "account_type",
                value: "brand"
            }
        },
        {
            index: 4,
            title: "Click Manage Permissions",
            image: "3.png",
            description: () => <Text fz="sm">You should see a page that looks like this. Click the <strong>Manage Permissions</strong> button under users. You may be prompted to enter your password.</Text>,
            condition: {
                type: "account_type",
                value: "brand"
            }
        },
        {
            index: 5,
            title: "Invite a user",
            description: ({ inviteIdentifier, accessLevel }) => <>
                <Text fz="sm">Click the <UserPlus size={18} /> icon at the top right of the modal. In the email box, type:</Text>
                <CopyBox mt={5} value={inviteIdentifier as string} />
                <Text mt={5} fz="sm">In the role box, select <strong>{accessLevel}</strong>.</Text>
                <Text fz="sm">Click <strong>Invite</strong> and you're all done!</Text>

            </>,
            condition: {
                type: "account_type",
                value: "brand"
            }
        },

        // This guide is for users with a personal account
        {
            index: 3,
            title: "Navigate to Invite Modal",
            description: () => <Text fz={"sm"}>Click the invite button at the top right of the modal. You should see a popup like this.</Text>,
            image: "5.png",
            condition: {
                type: "account_type",
                value: "personal"
            }
        },
        {
            index: 4,
            title: "Invite a user",
            description: ({ inviteIdentifier, accessLevel }) => <>
                <Text fz={"sm"}>In the email box, type:</Text>
                <CopyBox mt={5} value={inviteIdentifier as string} />
                <Text fz="sm" mt={5}>In the access box, select <strong>{accessLevel}</strong>.</Text>
                <Text fz="sm">Click <strong>Done</strong> and you're finished!</Text>
            </>,
            image: "5.png",
            condition: {
                type: "account_type",
                value: "personal"
            }
        },
    ],
    "Twitter Delegate Account": [
        {
            index: 0,
            title: "Navigate to delegated memebers",
            description: () => <Text fz="sm">Navigate to <InlinePageLink newTab href="https://twitter.com/settings/delegate/members">X delegated member settings</InlinePageLink>. Make sure you're logged into X. It should look something like this.</Text>,
            image: "3.png"
        },
        {
            index: 1,
            title: "Invite a member",
            description: ({ inviteIdentifier, accessLevel }) => <>
                <Text fz="sm">Click the <strong>invite a member button</strong>.</Text>
                <Text fz="sm" mt={5}>In the search box, type:</Text>
                <CopyBox mt={5} value={inviteIdentifier as string} />
                <Text mt={5} fz='sm'>After selecting the correct account, assign the <strong>{accessLevel}</strong> role to your new member. Click <strong>Send Invite</strong> and you're good to go!</Text>
            </>
        }
    ],
    "Twitter Ads": [
        {
            index: 0,
            title: "Navigate to X Ads",
            description: () => <Text fz="sm"><InlinePageLink newTab href="https://ads.twitter.com">Navigate to X ads</InlinePageLink>. Make sure you're logged into the correct account.</Text>
        },
        {
            index: 1,
            title: "Navigate to account settings",
            description: () => <Text fz="sm">Click your account name in the top right of the header. In the dropdown, click <strong>Edit access to account</strong>.</Text>,
            image: "1.png"
        },
        {
            index: 2,
            title: "Open add access",
            description: () => <Text fz="sm">You should see a screen similar to this. Click the <strong>Add access</strong> button to the right of your screen.</Text>,
            image: "2.png"
        },
        {
            index: 3,
            title: "Grant access",
            description: ({ inviteIdentifier, accessLevel }) => <>
                <Text fz="sm">In the name textbox, type:</Text>
                <CopyBox mt={5} value={inviteIdentifier as string} />
                <Text fz="sm" mt={5}>In the access dropdown, select <strong>{accessLevel}</strong></Text>
                <Text fz="sm">Click the <strong>Save Changes</strong> button and you're good to go!</Text>
            </>
        }
    ],
    "Instagram": [
        {
            index: 0,
            title: "Sign in with Meta",
            description: () => <>
                <Text fz="sm">Click the <strong>Sign in with Meta</strong> button</Text>
            </>,
            rightSection: ({ oAuthProviderButton }) => <>{oAuthProviderButton}</>
        },
        {
            index: 1,
            title: "Select a business account",
            description: () => <Text fz="sm">Choose the business account you'd like to grant access to from the dropdown above</Text>,
            rightSection: ({ assetSelector }) => <>{assetSelector}</>
        },
        {
            index: 2,
            title: "Navigate to Business Settings",
            description: ({ resource }) => <Text>Navigate to <InlinePageLink newTab href={`https://business.facebook.com/settings/partners${resource ? `?business_id=${resource[0]}` : ""}`}>Meta Business Settings</InlinePageLink>. It should look something like this.</Text>,
            image: "1.png"
        },
        {
            index: 3,
            title: "Share assets",
            description: ({ inviteIdentifier }) => <Text fz="sm">Find {inviteIdentifier} in the partners list and click on them. Then click the <strong>Share Assets</strong> button underneath their name.</Text>,
            image: "2.png"
        },
        {
            index: 4,
            title: "Grant Access",
            description: ({ accessLevel }) => <>
                <Text fz="sm">In the sidebar, select <strong>Instagram Accounts</strong></Text>
                <br />
                <Text fz="sm">Select the Instagram account you'd like to grant access to.</Text>
                <br />
                <Text fz="sm">Under Assign permissions, select {accessLevel === "Partial Access" ? <Text span fz="sm">eveything under the <strong>Partial access</strong> header</Text> : <Text fz="sm"><strong>Everything</strong> under the <strong>Full Control</strong> header.</Text>}</Text>
                <br />
                <Text fz="sm">Click <strong>Save Changes</strong> in the bottom right hand side, and you're good to go!</Text>
            </>,
            image: "3.png"
        }
    ],
    "Pinterest Ads": [
        {
            index: 0,
            title: "Navigate to Business Manager",
            description: () => <Text fz="sm"><InlinePageLink newTab href="https://www.pinterest.co.uk/business/business-manager">Pinterest Business Manager</InlinePageLink>. Make sure you're logged into the correct account.</Text>
        },
        {
            index: 1,
            title: "Navigate to partners",
            description: () => <Text fz="sm">In the sidebar, select <strong>Partners</strong></Text>,
            image: "1.png"
        },
        {
            index: 2,
            title: "Invite partner",
            description: ({ inviteIdentifier }) => <>
                <Text fz="sm">
                    Click <strong>Add a partner</strong>
                </Text>
                <Text fz="sm">
                    In the modal, under <strong>Partner Business ID</strong>, enter:
                </Text>
                <CopyBox mt={5} value={inviteIdentifier as string} />
                <Text fz="sm" mt={5}>
                    Then select <strong>Invite this partner to access your ad accounts and tools</strong> and click <strong>Add Partner</strong>
                </Text>
            </>,
            image: "2.png"
        },
        {
            index: 3,
            title: "Assign Permissions",
            description: ({ accessLevel }) => <>
                <Text fz="sm">
                    Select the Ad account you'd like to grant access to from the dropdown. Then, under permissions, select <strong>{accessLevel}</strong>
                </Text>
                <br />
                <Text fz="sm">Then click <strong>Assign Permissions</strong> and you're done!</Text>
            </>
        }
    ],
    "Meta Ads": [
        {
            index: 0,
            title: "Sign in with Meta",
            description: () => <>
                <Text mb={10} fz="sm">Click the <strong>Sign in with Meta</strong> button</Text>
            </>,
            rightSection: ({ oAuthProviderButton }) => <>{oAuthProviderButton}</>
        },
        {
            index: 1,
            title: "Select a business account",
            description: () => <Text fz="sm">Choose the business account you'd like to grant access to from the dropdown above</Text>
        },
        {
            index: 2,
            title: "Navigate to Business Settings",
            description: ({ resource, creds }) => {
                console.log(`https://business.facebook.com/settings/ad-accounts${`${resource ? `/${resource[0]?.replace("act_", "")}` : ""}`}?business_id=${creds?.Meta?.business?.id}`)
                return <Text fz="sm">Navigate to <InlinePageLink newTab href={`https://business.facebook.com/settings/ad-accounts${`${resource ? `/${resource[0]?.replace("act_", "")}` : ""}`}?business_id=${creds?.Meta?.business?.id}`}>Meta Business Settings</InlinePageLink>. It should look something like this.</Text>
            },
            image: "1.png"
        },
        {
            index: 3,
            title: "Assign a partner",
            description: () => <>
                <Text fz="sm">Select the ad account you'd like to grant access to and click <strong>Assign Partners</strong></Text>
                <Text></Text>
            </>,
            image: "2.png"
        },
        {
            index: 4,
            title: "Invite type",
            description: () => <Text fz="sm">When prompted, choose <strong>Business ID</strong> as your assignment method</Text>,
            image: "3.png"
        },
        {
            index: 5,
            title: "Grant access",
            description: ({ inviteIdentifier }) => <>
                <Text fz="sm">In the <strong>Partner Business ID</strong> field, enter:</Text>
                <CopyBox mt={5} value={inviteIdentifier as string} />
                <Text fz="sm" mt={5}>Then tick <strong>Manage ad accounts</strong> under full control.</Text>
                <Text fz="sm">Click <strong>Next</strong> and wait for the indicator above to change from <Text fz="sm" span fw={600} c="orange">Waiting</Text> to <Text fz="sm" span fw={600} c="blue">Successful</Text></Text>
            </>,
            image: "4.png"
        }
    ],
    "Amazon Ads": [
        {
            index: 0,
            title: "Navigate to the dashboard",
            description: () => <>
                <Text fz="sm">Navigate to the <InlinePageLink newTab href="https://advertising.amazon.com/">Amazon Ads dashboard</InlinePageLink> and log in.</Text>
                <Text fz="sm">Once you're in the dashboard, click <strong>Administration</strong> in the bottom left. Then click <strong>Account Access & Settings</strong></Text>
            </>,
            image: "1.png"
        },
        {
            index: 1,
            title: "Find your account ID",
            description: ({ form, setFormValue }) => <>
                <Text fz={"sm"}>Once in <strong>Account Access & Settings</strong>, you should see your account ID right under your account name.</Text>
                <br />
                <Text fz={"sm"}>Copy the account ID and paste it into the textbox below.</Text>
                {form?.map((input) => <TextInput value={input.value[0]} placeholder={input.placeholder} mt={10} label={input.label} onChange={(e) => { if (!setFormValue) return; setFormValue(input.id, [e.target.value]) }} />)}
            </>,
            image: "2.png"
        },
        {
            index: 2,
            title: "Grant access",
            description: ({ inviteIdentifier, agencyName }) => { // In this case, the inviteIdentifier will be the URL the user uses to grant access
                if (!inviteIdentifier) {
                    return (
                        <Box className="flex" style={{ gap: 18 }}>
                            <Indicator mt={8} ml={10} processing color="orange" />
                            <Box>
                                <Text lh={1} fw={600} fz="lg">Come back soon...</Text>
                                <Text mt={4} fz="sm">We've passed on your account ID to {agencyName}. They'll generate an Amazon Ads confirmation url for you which you can use to finish granting access. You'll get an email notification when the URL is ready to go!</Text>
                            </Box>
                        </Box>
                    )
                }

                return (
                    <>
                        <Text fz={"sm"}><strong>Click the button below to grant access</strong> via the Amazon Ads dashboard. Make sure you're logged into the correct account.</Text>
                    </>
                )
            }
        }
    ],
    "Shopify Store Collaborator": [
        {
            index: 0,
            title: "Navigate to the dashboard",
            description: () => <>
                <Text fz={"sm"}>Navigate to the <InlinePageLink newTab href={`https://admin.shopify.com`}>Shopify Dashboard</InlinePageLink> and log in to your store.</Text>
                <Text fz="sm">Once you're in the dashboard, click <strong>Settings</strong> in the bottom left. Then click <strong>Users and permissions</strong></Text>
            </>,
            image: "1.gif"
        },
        {
            index: 1,
            title: "Find your Collaborator Code",
            description: ({ form, setFormValue }) => <>
                <Text fz='sm'>Find your <strong>Collaborator Request Code</strong> and copy & paste it into the textbox below.</Text>
                {form?.map((input) => input.id === "client_account_id" ? <TextInput value={input.value[0]} placeholder={input.placeholder} mt={7} label={input.label} onChange={(e) => { if (!setFormValue) return; setFormValue(input.id, [e.target.value]) }} /> : null)}
                <Text mt={5} fz='sm'>This code is used to invite the requesting agency to your account.</Text>
            </>,
            image: "2.gif"
        },
        {
            index: 2,
            title: "Find your Store URL",
            description({ form, setFormValue, theme }) {
                return <>
                    <Text fz='sm'>If you know your store URL already, enter it in the textbox below. Otherwise, in settings, navigate to <strong>Domains</strong>, and enter the active domain for your store.</Text>
                    {form?.map((input) => input.id === "store_url" ? <TextInput color={theme.color || "dark"} value={input.value[0]} placeholder={input.placeholder} mt={7} label={input.label} onChange={(e) => { if (!setFormValue) return; setFormValue(input.id, [e.target.value]) }} /> : null)}
                </>
            },
            image: "3.gif"
        },
        {
            index: 3,
            title: "Grant Access",
            description: ({ inviteIdentifier, agencyName }) => { // In this case, the inviteIdentifier will be the URL the user uses to grant access
                if (!inviteIdentifier) {
                    return (
                        <Box className="flex" style={{ gap: 18 }}>
                            <Indicator mt={8} ml={10} processing color="orange" />
                            <Box>
                                <Text lh={1} fw={600} fz="lg">Come back soon...</Text>
                                <Text mt={4} fz="sm">We've passed on your collaborator code to {agencyName}. They'll generate a confirmation request for you which you can accept to finish granting access. You'll get an email notification from us when the request is ready to accept.</Text>
                            </Box>
                        </Box>
                    )
                }

                return (
                    <>
                        <Text fz={"sm"}>Navigate to the <strong>Shopify Dashboard</strong> and accept the incoming collaborator request.</Text>
                    </>
                )
            }
        }
    ],
    "Snapchat Ads": [
        {
            index: 0,
            title: "Navigate to Ads Manager",
            description: () => <>
                <Text fz={"sm"}>Navigate to <InlinePageLink href="https://ads.snapchat.com/" newTab>Snapchat Ads Manager</InlinePageLink> and log in.</Text>
            </>
        },
        {
            index: 1,
            title: "Pick an option",
            description: ({ agencyName }) => <>
                <Text fz={"sm"}>Have you already invited {agencyName} to your Snapchat Business account?</Text>
            </>,
            decisionPoint: {
                id: "is_invited",
                choices: [
                    { label: "Yes", value: "yes" },
                    { label: "No", value: "no" }
                ]
            }
        },
        {
            index: 2,
            title: "Navigate to Members",
            description: () => <>
                <Text fz={"sm"}>Once you're logged in, navigate to <strong>Members</strong> using the menu in the top left.</Text>
            </>,
            condition: {
                type: "is_invited",
                value: "no"
            },
            image: "4.png"
        },
        {
            index: 3,
            title: "Invite a Member",
            description: ({ inviteData, inviteIdentifier }) => <>
                <Text fz="sm">Click the <strong>Invite Members</strong> button at the top right of the screen.</Text>
                <Text fz={"sm"}>In the email field, type:</Text>
                <CopyBox value={inviteIdentifier as string} mt={5} />
                <Text fz="sm" mt={10}>Under <strong>Role</strong>, select <strong>{inviteData?.requestedAccounts.Snapchat["Snapchat Business"].accessLevel}</strong>.</Text>
                <Text fz="sm">Click <strong>Invite Members</strong> and move on to the next step.</Text>
            </>,
            condition: {
                type: "is_invited",
                value: "no"
            },
            image: "5.png"
        },
        {
            index: 2,
            title: "Navigate to Ad Accounts",
            description: ({ }) => <>
                <Text fz={"sm"}>Once you're logged in, navigate to <strong>Ad Accounts</strong> using the menu in the top left.</Text>
            </>,
            image: "1.png",
        },
        {
            index: 3,
            title: "Navigate to Members",
            description: ({ }) => <>
                <Text fz={"sm"}>Select your ad account from the list, then select <strong>Members and Billing</strong> in the left hand panel.</Text>
            </>,
            image: "2.png"
        },
        {
            index: 4,
            title: "Add a Member",
            description: ({ inviteIdentifier, accessLevel }) => <>
                <Text fz="sm">Click the <strong>Invite Members</strong> button at the top right of the screen.</Text>
                <Text fz={"sm"}>In the email field, type:</Text>
                <CopyBox value={inviteIdentifier as string} mt={5} />
                <Text fz="sm" mt={10}>Under <strong>Role</strong>, select <strong>{accessLevel}</strong>.</Text>
                <Text fz="sm">Click <strong>Invite Members</strong> and you're all done!</Text>
            </>,
            image: "3.png"
        }
    ],
    "Snapchat Business": [
        {
            index: 0,
            title: "Navigate to Ads Manager",
            description: () => <>
                <Text fz={"sm"}>Navigate to <InlinePageLink href="https://ads.snapchat.com/" newTab>Snapchat Business Manager</InlinePageLink> and log in.</Text>
            </>
        },
        {
            index: 1,
            title: "Navigate to Members",
            description: () => <>
                <Text fz={"sm"}>Once you're logged in, navigate to <strong>Members</strong> using the menu in the top left.</Text>
            </>,
            image: "1.png"
        },
        {
            index: 2,
            title: "Invite a Member",
            description: ({ accessLevel, inviteIdentifier }) => <>
                <Text fz="sm">Click the <strong>Invite Members</strong> button at the top right of the screen.</Text>
                <Text fz={"sm"}>In the email field, type:</Text>
                <CopyBox value={inviteIdentifier as string} mt={5} />
                <Text fz="sm" mt={10}>Under <strong>Role</strong>, select <strong>{accessLevel}</strong>.</Text>
                <Text fz="sm">Click <strong>Invite Members</strong> and you're done!</Text>
            </>,
            image: "2.png"
        },
    ],
    "HubSpot Partner": [
        {
            index: 0,
            title: "Follow access link",
            description: ({ inviteIdentifier }) => <Text fz='sm'>Follow <InlinePageLink href={inviteIdentifier as string} newTab>this link</InlinePageLink> and select the account you want to share. Once access has been granted, click the confirmation button below.</Text>
        }
    ],
    "ProfitMetrics Partner": [
        {
            index: 0,
            title: "Send an email to ProfitMetrics support",
            description: ({ inviteIdentifier }) => {
                const [domain, setDomain] = useState("")
                const [name, setName] = useState("")
                const [companyName, setCompanyName] = useState("")
                const contentRef = useRef()
                return (
                    <Box>
                        <Text fz="sm">To grant access, you must send a request to the ProfitMetrics team.</Text>
                        <Text fz="sm">Use this email template to request access</Text>
                        <Paper mt={5} withBorder shadow="sm" p="sm" radius={10}>
                            <Text fz="sm"><strong>To:</strong> support@profitmetrics.io</Text>
                            <Text fz="sm"><strong>Subject:</strong> Can you give my agency access</Text>
                            {/* @ts-ignore */}
                            <Text ref={contentRef} mt={10} lh={1.2} fz="sm">
                                Dear ProfitMetrics Support,
                                <br />
                                <br />
                                I would like you to grant access to my ProfitMetrics account for the domain <InlineInput value={domain} setValue={setDomain} placeholder="your domain" />.
                                <br />
                                <br />
                                The agency is {inviteIdentifier}.
                                <br />
                                Please let me know once access has been granted.

                                <br />
                                <br />
                                Best regards,
                                <br />
                                <InlineInput value={name} setValue={setName} placeholder="Full Name" />, {" "}
                                <InlineInput value={companyName} setValue={setCompanyName} placeholder="Company Name" />
                            </Text>
                        </Paper>
                        {/* @ts-ignore */}
                        <CopyButton value={contentRef.current?.innerText}>
                            {({ copied, copy }) => (
                                <Button onClick={copy} bg={copied ? "green.1" : undefined} variant="default" mt={7} leftSection={copied ? <Check size={20} /> : <Copy size={20} />} fullWidth>{copied ? "Copied" : "Copy to Clipboard"}</Button>
                            )}
                        </CopyButton>
                        <Divider label="then" my={3} mb={5} />
                        <Box className="flex aic" style={{ gap: 10 }}>
                            {/* @ts-ignore */}
                            <Button onClick={() => window.open(`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${'support@profitmetrics.io'}&su=${encodeURIComponent("Can you give my agency access")}&body=${encodeURIComponent(contentRef.current.innerText)}`)} variant="default" style={{ flex: 1 }} leftSection={<Image src={`/images/logos/gmail.webp`} width={20} height={20} />}>Open Gmail</Button>
                            <Button onClick={() => window.open(`https://outlook.live.com/mail/0/`)} variant="default" style={{ flex: 1 }} leftSection={<Image src={`/images/logos/outlook.webp`} width={20} height={20} />}>Open Outlook</Button>
                        </Box>
                    </Box>
                )
            }
        }
    ]
} as Partial<Record<AnyService, Array<Instruction>>>