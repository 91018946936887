import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@/styles/globals.css'
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Button, createTheme, Input, MantineProvider, Select, ActionIcon, ColorSchemeScript, Alert, Modal, NumberInput, Box } from '@mantine/core';
import DefaultLayout from '@/layouts/Default.layout';
import { useRouter } from 'next/router';
import DashboardLayout from '@/layouts/Dashboard.layout';
import { useEffect, useState } from 'react';
import TrialLayout from '@/layouts/Trial.layout';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { CurrencyContext, CurrencyControllerContext } from '@/utils/context/Currency.context';
import api from '@/utils/api';
import { Currency, Platform, User } from '../../../types/global';
import { auth, firestore } from '@/utils/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { PlatformsContext } from '@/utils/context/Platforms.context';
import { LoadingScreen } from '@/components/LoadingScreen';
import SettingsLayout from '@/layouts/Settings.layout';
import PlatformsProvider from '@/providers/Platforms.provider';
import AdminProvider from '@/providers/Admin.provider';
import AdminLayout from '@/layouts/Admin.layout';
import BlogLayout from '@/layouts/Blog.layout';
import InviteLayout from '@/layouts/Invite.layout';
import OnboardingLayout from '@/layouts/Onboarding.layout';

const theme = createTheme({
    components: {
        Button: Button.extend({
            defaultProps: {
                radius: 10,
            }
        }),
        TextInput: Input.extend({
            defaultProps: {
                radius: 10
            }
        }),
        PasswordInput: Input.extend({
            defaultProps: {
                radius: 10
            }
        }),
        NumberInput: NumberInput.extend({
            defaultProps: {
                radius: 10
            }
        }),
        Select: Select.extend({
            defaultProps: {
                radius: 10
            },
            styles: {
                dropdown: {
                    zIndex: 100000
                }
            }
        }),
        ActionIcon: ActionIcon.extend({
            defaultProps: {
                variant: "subtle",
                color: "gray"
            }
        }),
        Alert: Alert.extend({
            styles: {
                body: {
                    marginTop: 0
                },
                message: {
                    marginTop: 0
                }
            }
        }),
        Modal: Modal.extend({
            styles: {
                header: {
                    paddingBottom: 5
                }
            }
        })
    },
    primaryColor: "dark"
})

export default function App(props: AppProps) {
    const { Component, pageProps } = props;
    const router = useRouter()
    const [currency, setCurrency] = useState<Currency>("USD")
    // const [platforms, setPlatforms] = useState<Array<Platform> | undefined>(undefined)

    useEffect(() => {
        api("/public/geo", {}, false, "get").then((res) => {
            if (res.data) {
                setCurrency(res.data)
            }
        })
    }, [])

    useEffect(() => {
        const { ref } = router.query
        if (ref) {
            window.localStorage.setItem("ref", ref as string)
        }
    }, [router])

    useEffect(() => {
        if (global?.window) {
            window.localStorage.setItem("currency", currency)
            if (auth.currentUser) {
                updateDoc(doc(firestore, "users", auth.currentUser.uid), {
                    currency
                } as Partial<User>)
            }

        }
    }, [currency])

    useEffect(() => {
        setTimeout(() => {
            console.log("%cSTOP!",
                "color:red; font-size:40px;")
            console.log("%cThis is a browser feature intended for developers. If someone told you to copy and paste something here, it is a scam and will give them access to your AgencyAccess linked accounts.",
                "font-size:25px;")
        }, 10000)
    }, [])
    return (
        <>
            <Head>
                <title>AgencyAccess | Credentials Made Simple</title>
                <script src="https://cdn.jsdelivr.net/gh/greentfrapp/pocoloco@minigl/minigl.js"></script>
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
                <script dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5HWZVKHB');`}} />
                <script type="text/javascript"
                    dangerouslySetInnerHTML={{
                        __html: `
                        var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
                        (function(){
                        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                        s1.async=true;
                        s1.src='https://embed.tawk.to/660356f1a0c6737bd1253303/1hpugn7lv';
                        s1.charset='UTF-8';
                        s1.setAttribute('crossorigin','*');
                        s0.parentNode.insertBefore(s1,s0);
                        })();
                        `}}
                >
                </script>
            </Head>
            <ColorSchemeScript forceColorScheme="light" />
            <MantineProvider
                defaultColorScheme="light"
                forceColorScheme={!router.pathname.startsWith("/i") ? "light" : undefined}
                theme={theme}
            >
                <CurrencyContext.Provider value={currency}>
                    <CurrencyControllerContext.Provider value={{ set: (newCurrency: Currency) => setCurrency(newCurrency) }}>
                        <ModalsProvider>
                            <Notifications position="top-center" />
                            {router.pathname.startsWith("/dashboard")
                                ? <DashboardLayout>
                                    {router.pathname.startsWith("/dashboard/onboarding")
                                        ? <OnboardingLayout>
                                            <Component {...pageProps} />
                                        </OnboardingLayout>
                                        : <Component {...pageProps} />
                                    }

                                </DashboardLayout>
                                : <>
                                    {router.pathname.startsWith("/settings")
                                        ? <SettingsLayout>
                                            <Component {...pageProps} />
                                        </SettingsLayout>
                                        : <>
                                            {router.pathname.startsWith("/i")
                                                ? <InviteLayout>
                                                    <Component {...pageProps} />
                                                </InviteLayout>
                                                : <>
                                                    {router.pathname.startsWith("/admin")
                                                        ? <AdminLayout>
                                                            <Component {...pageProps} />
                                                        </AdminLayout>
                                                        : <DefaultLayout>
                                                            <>
                                                                {router.pathname.startsWith("/auth/trial")
                                                                    ? <TrialLayout>
                                                                        <Component {...pageProps} />
                                                                    </TrialLayout>
                                                                    : <Component {...pageProps} />
                                                                }
                                                            </>


                                                        </DefaultLayout>
                                                    }
                                                </>
                                            }
                                        </>
                                    }

                                </>
                            }
                        </ModalsProvider>
                    </CurrencyControllerContext.Provider>
                    {/* <PlatformsContext.Provider value={platforms as Array<Platform>}> */}

                </CurrencyContext.Provider>

                {/* </PlatformsContext.Provider> */}
            </MantineProvider>
        </>
    );
}
