import React, { useContext, useEffect, useState } from 'react'
import { StaticLink, StaticLinkPublicData } from '../../../../types/global'
import ThemeProvider from '@/providers/Theme.provider';
import { InviteBodyContainer, InviteContainer } from '@/pages/i/[id]';
import api from '@/utils/api';
import { Box, Button, Text, TextInput } from '@mantine/core';
import { ThemeContext } from '@/utils/context/Theme.context';
import Error from '../Error';

const OnboardingForm = ({ staticLinkData }: { staticLinkData: StaticLinkPublicData }) => {
    const theme = useContext(ThemeContext)
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [company, setCompany] = useState("")
    return (
        <>
            <Text ta="center" mt={0} fw={700}><Text component={"span"} fz={25} fw={700}>🏢 {theme.displayName || staticLinkData.agency.name} Onboarding </Text> <br /> <Text fw={500} component={"span"} fz="md">Complete the form below to get started</Text></Text>
            <Box mt={20} w={"100%"} px={20} maw={500} style={{ gap: 10 }} className='flex fdc'>
                <TextInput value={name} onChange={(e) => setName(e.target.value)} label="Name" placeholder='Joe Smith' required w={"100%"} />
                <TextInput value={email} onChange={(e) => setEmail(e.target.value)} type="email" label="Email" placeholder='joe@example.com' required w={"100%"} />
                <TextInput value={company} onChange={(e) => setCompany(e.target.value)} label="Company" placeholder='Acme' w={"100%"} />
                <Button disabled={!name || !email} loading={loading} mt={8} fullWidth onClick={() => {
                    setLoading(true)
                    api("/invite/static/create-session", {
                        staticLinkID: staticLinkData.id,
                        clientData: {
                            name,
                            email,
                            company
                        }
                    }).then((res) => {
                        if (res.error) {
                            setLoading(false)
                            return setError(res.msg)
                        }
                        const staticLinkRefs = JSON.parse(window.localStorage.getItem("static_link_sessions") || "{}")
                        staticLinkRefs[staticLinkData.slug] = res.data
                        window.localStorage.setItem("static_link_sessions", JSON.stringify(staticLinkRefs))
                        window.location.reload()
                    })
                }}>Continue</Button>
                <Error>{error}</Error>
            </Box>
        </>
    )
}

export default function StaticSessionForm({ slug }: { slug: string }) {
    const [staticLinkData, setStaticLinkData] = useState<StaticLinkPublicData | undefined>()
    const [error, setError] = useState("")

    useEffect(() => {
        api("/invite/details/static", {
            slug
        }).then((res) => {
            if (res.error) {
                return setError(res.msg)
            }
            setStaticLinkData(res.data)
        })
    }, [])

    if (!staticLinkData) return null;
    return (
        <ThemeProvider agencyID={staticLinkData.agency.id}>
            <InviteContainer>
                {() => <InviteBodyContainer>
                    <OnboardingForm staticLinkData={staticLinkData} />
                </InviteBodyContainer>}
            </InviteContainer>
        </ThemeProvider>
    )
}
