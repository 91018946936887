import { doc, onSnapshot } from 'firebase/firestore'
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { Agency, CouponObject } from '../../../types/global'
import api from '../utils/api'
import { AgencyContext } from '../utils/context/Agency.context'
import { CouponContext } from '../utils/context/Coupon.context'
import { firestore } from '../utils/firebase'

export default function CouponProvider({ children }: PropsWithChildren) {
    const agencyData = useContext(AgencyContext)
    const [code, setCode] = useState<CouponObject | null>(null)

    useEffect(() => {
        if (!agencyData) {
            return
        }
        // const unsub = onSnapshot(doc(firestore, "agencies", agency.id), (snap) => {
        //     const agencyData = snap.data() as Agency
        if (agencyData.activeCouponID && agencyData.activeCouponID !== code?.id) {

            api("/stripe/coupon/retrieve", {
                coupon: agencyData.activeCouponID
            }).then((res) => {
                setCode(res.data || null)
            })
        } else {
            if (!agencyData.activeCouponID) {
                setCode(null)
            }
        }
        // })

        // return () => unsub()
    }, [agencyData])


    return (
        <CouponContext.Provider value={code}>
            {children}
        </CouponContext.Provider>
    )
}


