import OnboardingModalController from '@/components/dashboard/OnboardingModal'
import WelcomeModalController from '@/components/dashboard/WelcomeModel'
import { DashboardHeader } from '@/components/Headers'
import { LoadingScreen } from '@/components/LoadingScreen'
import Navbar from '@/components/Navbar'
import api from '@/utils/api'
import { AgencyContext } from '@/utils/context/Agency.context'
import { ClientsContext } from '@/utils/context/Clients.context'
import { PlatformsContext } from '@/utils/context/Platforms.context'
import { SubscriptionContext } from '@/utils/context/Subscription.context'
import { UserContext } from '@/utils/context/User.context'
import { auth, firestore } from '@/utils/firebase'
import CouponProvider from '@/providers/Coupon.provider'
import { Box, Button, Paper, Text } from '@mantine/core'
import { onAuthStateChanged } from 'firebase/auth'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { PropsWithChildren, useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { AlertCircle, InfoCircle } from 'tabler-icons-react'
import { Agency, Client, Platform, User } from '../../../types/global'
import OAuthLayout from './OAuth.layout'
import UserProvider from '@/providers/User.provider'
import AgencyProvider from '@/providers/Agency.provider'
import ClientsProvider from '@/providers/Clients.provider'
import SubscriptionProvider from '@/providers/Subscription.provider'
import AgencySelectModalProvider from '@/providers/AgencySelectModal.provider'
import PlatformsProvider from '@/providers/Platforms.provider'
import ManualGrantsProvider from '@/providers/ManualGrants.provider'



const MainContentController = ({ children }: PropsWithChildren) => {
    const router = useRouter()
    const agency = useContext(AgencyContext)
    const subscriptionActive = useContext(SubscriptionContext)
    return (
        <>

            <main style={{ padding: 25, flex: 1, background: "#f9f9f9", overflowY: "auto", position: "relative", minWidth: 850 }} className="flex jcc">
                <div style={{ maxWidth: 1500, width: "100%", paddingBottom: 50, height: "100%" }} className='flex fdc'>
                    {!subscriptionActive && agency.subscriptionExpires as number < Date.now()
                        ? <Paper withBorder radius={"lg"} p="md" mb={15} color={"red"}>
                            <div className='flex aic jcsb' style={{ gap: 15 }}>
                                <div className='flex' style={{ gap: 10 }}>
                                    <AlertCircle color='#fa5252' />
                                    <div>
                                        <Text fw={700} fz={16} c={"red"}>Subscription Expired</Text>
                                        <Text fz={"sm"}>
                                            Your subscription has expired. Please renew your subscription to regain access to AgencyAccess.
                                        </Text>
                                    </div>

                                </div>
                                <Link href={"/dashboard/settings/billing"}>
                                    <Button variant={"default"}>Manage Subscription</Button>
                                </Link>
                            </div>
                        </Paper>
                        : <>
                            {!subscriptionActive
                                ? <Paper mb={15} bg="red.1" p={15} color={"red"} title="Your subscription has been canceled">
                                    <div className='flex' style={{ gap: 10 }}>
                                        <InfoCircle color='var(--mantine-color-red-6)' />
                                        <div>
                                            <Text c="red" fw={600}>Your subscription has been canceled</Text>
                                            <Text c="black" fz={"sm"}>You'll loose access to your account on <strong>{new Date(agency.subscriptionExpires as number).toDateString()}</strong>. Renew your subscription to maintain access.</Text>
                                            <Link href={"/dashboard/settings/billing"}>
                                                <Button variant={"default"} mt={10}>Manage Subscription</Button>
                                            </Link>
                                        </div>
                                    </div>

                                </Paper>
                                : null
                            }
                        </>
                    }

                    <div style={{ position: "relative", flex: 1, paddingBottom: 25, minHeight: "100%" }}>
                        {!subscriptionActive && agency.subscriptionExpires as number < Date.now() && !window.location.pathname.startsWith("/dashboard/settings/billing")
                            ? <Box style={{ position: "absolute", zIndex: 10000, width: "100%", height: "100%", left: 0, right: 0, background: 'rgba(255,255,255, .5)' }}></Box>
                            : null
                        }
                        {/* {router.pathname.startsWith("/dashboard/oauth")
                            ? <OAuthLayout>
                                {children}
                            </OAuthLayout>
                            : <>{children}</>
                        } */}
                        {children}
                    </div>
                    {/* <OnboardingModalController /> */}
                    <WelcomeModalController />
                </div>
            </main>
        </>
    )
}

const MaintainanceBanner = () => {
    return (
        <Box className='flex aic' bg="red" p="sm" style={{ gap: 15 }}>
            <AlertCircle size={20} color='#fff' />
            <Box>
                <Text fw={600} lh={1} c="#fff">Maintenance is taking place between 9AM-10:30AM CET</Text>
                <Text fz="sm" lh={1} mt={6} c="rgba(255,255,255,.6)">You may experience a slowdown when using the billing page. We apologise for any inconvenience this causes.</Text>
            </Box>
        </Box>
    )
}


export default function DashboardLayout({ children }: { children: React.ReactNode }) {
    return (
        <UserProvider>
            <PlatformsProvider>
                <ManualGrantsProvider>
                    <AgencySelectModalProvider>
                        <AgencyProvider>
                            <CouponProvider>
                                <SubscriptionProvider>
                                    <ClientsProvider>
                                        {/* <MaintainanceBanner /> */}
                                        <DashboardHeader />
                                        <div className='flex' style={{ height: "calc(100vh - 80px)", overflowX: "auto" }}>
                                            <Navbar />
                                            <MainContentController>{children}</MainContentController>
                                        </div>
                                    </ClientsProvider>
                                </SubscriptionProvider>
                            </CouponProvider>
                        </AgencyProvider>
                    </AgencySelectModalProvider>
                </ManualGrantsProvider>
            </PlatformsProvider>
        </UserProvider>
    )
}
