import { PlatformsContext } from '@/utils/context/Platforms.context'
import { ThemeContext } from '@/utils/context/Theme.context'
import { roleValueToLabel } from '@/static/roles'
import { Box, Button, Paper, Text, Accordion } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import Image from 'next/image'
import React, { useContext } from 'react'
import { CircleCheck } from 'tabler-icons-react'
import { AccountTypes, AnyService, Invite } from '../../../../types/global'

export const ThankYouScreen = ({ invite }: { invite: Invite }) => {
    const theme = useContext(ThemeContext)
    const vertical = useMediaQuery("(max-width: 550px)")
    const platforms = useContext(PlatformsContext)
    return (
        <Paper radius={"xl"} mt={20} bg={theme.theme === "dark" ? "#171923" : undefined} w={"100%"} maw={550} p={30} className='flex aic jcc fdc'>
            <div className='flex aic jcc fdc'>
                <CircleCheck size={40} color={theme.theme === "dark" ? "#fff" : "#000"} />
                <Text fw={600} fz={25}>Thank you</Text>
            </div>
            <div className='flex aic jcc fdc'>
                <Text c="dimmed" ta={"center"}>{invite.thankYouMessage || `Access to the requested services has been granted${invite.intakeForm?.compelte ? `, and your intake form has been submitted` : ""}`}</Text>
            </div>
            <Box w="100%" mt={20}>
                <Accordion variant={"separated"}>
                    {Object.keys(invite.requestedAccounts).map((account) => {

                        return (
                            <Accordion.Item value={account} styles={{ item: { borderRadius: 10 } }}>
                                <Accordion.Control styles={{}}>
                                    <div className={`flex ${!vertical ? "aic" : ""} jcsb`} style={{ gap: 15, flexDirection: vertical ? "column" : "row" }}>
                                        <div className='flex aic' style={{ gap: 15 }}>
                                            <Image alt={account} width={30} height={30} style={{ borderRadius: 50, objectFit: "contain", backgroundColor: "#fff", padding: 5 }} src={`/images/logos/${account.toLowerCase().replaceAll(" ", "_")}.png`} />
                                            <div>
                                                <Text fz={"md"} fw={500}>{account} Accounts</Text>
                                            </div>
                                        </div>

                                    </div>
                                </Accordion.Control>
                                <Accordion.Panel>
                                    <div className='flex fdc' style={{ gap: 10 }}>
                                        {(Object.keys(invite.requestedAccounts[account as AccountTypes]) as Array<AnyService>).map((service) => {
                                            return (
                                                <div key={service} className={`flex ${vertical ? "" : "aic"} jcsb`} style={{ width: "100%", flexDirection: vertical ? "column" : "row" }}>
                                                    <Box className='flex' style={{ gap: 7 }}>
                                                        <Image width={25} height={25} alt={`${service} logo`} style={{ borderRadius: 50, objectFit: "contain", backgroundColor: "#f1f1f1", padding: 2 }} src={`/images/logos/${service.toLowerCase().replaceAll(" ", "_")}.png`} />

                                                        <div>
                                                            <Text fw={400} fz={"sm"}>{service}</Text>
                                                            <Text fz={"xs"} c="dimmed">{roleValueToLabel(service, invite.requestedAccounts[account as AccountTypes][service].accessLevel, platforms)}</Text>
                                                        </div>
                                                    </Box>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </Accordion.Panel>
                            </Accordion.Item>
                            // <Paper mt={20} w="100%" bg={theme.theme === "dark" ? "dark.9" : "#f9f9f9"} p="md" radius={"lg"}>
                            //     <>
                            //         <div className={`flex ${!vertical ? "aic" : ""} jcsb`} style={{ gap: 15, flexDirection: vertical ? "column" : "row", marginBottom: 10 }}>
                            //             <div className='flex aic' style={{ gap: 15 }}>
                            //                 <Image alt={account} width={30} height={30} style={{ borderRadius: 50, objectFit: "contain", backgroundColor: "#fff", padding: 5 }} src={`/images/logos/${account.toLowerCase().replaceAll(" ", "_")}.png`} />
                            //                 <div>
                            //                     <Text fz={"md"} fw={500}>{account} Accounts</Text>
                            //                 </div>
                            //             </div>

                            //         </div>

                            //     </>
                            // </Paper>
                        )
                    })}

                </Accordion>

            </Box>
            {invite.redirect
                ? <Button size={"md"} h="fit-content" bg={theme.color || "dark"} mt={20} fullWidth onClick={() => {
                    window.location.replace(invite.redirect as string)
                }}>
                    <Text py={8} lh={1} fw={500}>
                        Continue to<br />
                        <Text lh={1.1} fz="xs">{new URL(invite.redirect).host}</Text>
                    </Text>
                </Button>
                : null
            }
        </Paper>
    )
}