import {
    HoverCard,
    Group,
    Button,
    Text,
    Divider,
    Box,
    Burger,
    Drawer,
    Collapse,
    ScrollArea,
    rem,
    Paper,
    Avatar,
    TextInput,
    Accordion,
    ActionIcon,
    Badge,
} from '@mantine/core';
import { Spotlight, SpotlightActionData, spotlight } from '@mantine/spotlight';
import { useDisclosure, useHover, useMediaQuery } from '@mantine/hooks';
import {
    ChevronDown,
    ArrowRight,
    Search,
    DeviceDesktopAnalytics,
    Plus,
    UserPlus,
    Paint,
    Link as LinkIcon,
    Settings,
    Check,
    InfoCircle,
    X,
    Logout
} from 'tabler-icons-react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import { Logo } from './Logo';
import { UserContext } from '@/utils/context/User.context';
import { AccountTypes, AnyService, Currency, GoogleServices, MetaServices, PinterestServices, TwitterServices } from '../../../types/global';
import '@mantine/spotlight/styles.css';
import { AccessLinkStateController } from './dashboard/AccessLinkModal';
import { ClientCreateStateController } from './dashboard/ClientCreateModal';
import { CurrencyContext, CurrencyControllerContext } from '@/utils/context/Currency.context';
import { CurrencyController } from './landing/CurrencyController';

const AccountTypeTitle = ({ accountType }: { accountType: AccountTypes }) => {
    const mobile = useMediaQuery("(max-width: 960px)")
    return (<div className='flex aic' style={{ gap: 15 }}>
        <Image alt={`${accountType} Logo`} width={mobile ? 30 : 35} height={mobile ? 30 : 35} style={{ objectFit: "contain", borderRadius: 25, background: "#f9f9f9", padding: 5 }} src={`/images/logos/${accountType.toLowerCase().replaceAll(" ", "_")}.png`} />
        <Text style={{}} fw={600} fz={mobile ? "md" : "lg"}>{accountType}</Text>
    </div>)
}

const Service = ({ service }: { service: AnyService }) => {
    const { hovered, ref } = useHover()
    const mobile = useMediaQuery("(max-width: 960px)")
    return (
        <Link href={`/service/${service.replaceAll(" ", "-")}`}>
            <Box px={mobile ? 0 : "sm"} py={mobile ? 10 : "lg"} ref={ref} className="flex aic jcsb cp" style={{ borderRadius: 0 }} bg={hovered ? "#f9f9f9" : "transparent"}>
                <div className='flex aic' style={{ gap: 10 }}>
                    <Image alt={`${service} logo`} width={30} height={30} style={{ borderRadius: 30, background: hovered ? "#fff" : "#f9f9f9", objectFit: "contain", padding: 5 }} src={`/images/logos/${service.toLowerCase().replaceAll(" ", "_")}.png`} />
                    <div>
                        <Text fw={500} lineClamp={1} c="#000">{service}</Text>
                        {/* <Collapse in={hovered}>
                            <Text c={"dimmed"} fz="xs">Click to learn about integration</Text>
                        </Collapse> */}
                    </div>
                </div>
                <ArrowRight size={16} color={"#000"} />
            </Box>
        </Link >
    )
}

const ServiceList = ({ services }: { services: Array<AnyService> }) => {
    const mobile = useMediaQuery("(max-width: 960px)")
    return (
        <div className='flex fdc' style={{ gap: 0 }}>
            {services.map((service) => {
                return <>
                    <Service service={service} />
                    {/* {i === 0
                        ? null
                    } */}
                    {!mobile && <Divider style={{ opacity: .4 }} />}
                </>
            })}
        </div>
    )
}
const IntegrationDropdown = () => {
    const mobile = useMediaQuery("(max-width: 960px)")
    return (
        <Box className={`flex ${mobile ? "fdc" : ""}`} style={{ gap: 20 }}>
            <Box style={{ flex: 1, borderRadius: 10 }} p={mobile ? "md" : 0} bg={mobile ? "#f9f9f9" : "#fff"}>
                <AccountTypeTitle accountType="Google" />
                <Divider mt={10} variant="dashed" />
                <ServiceList services={["Google Ads", "Google Analytics", "Google Search Console", "Google Tag Manager", "Google Merchant Center", "Google Business Profile"] as Array<GoogleServices>} />
            </Box>
            <Box style={{ flex: 1, borderRadius: 10 }} p={mobile ? "md" : 0} bg={mobile ? "#f9f9f9" : "#fff"}>
                <AccountTypeTitle accountType="Meta" />
                <Divider mt={10} variant="dashed" />
                <ServiceList services={["Meta Ads", "Facebook Pages", "Facebook Product Catalog"] as Array<MetaServices>} />
            </Box>
            <Box style={{ flex: 1, borderRadius: 10 }} p={mobile ? "md" : 0} bg={mobile ? "#f9f9f9" : "#fff"}>
                <AccountTypeTitle accountType="Pinterest" />
                <Divider mt={10} variant="dashed" />
                <ServiceList services={["Pinterest Ads"] as Array<PinterestServices>} />
            </Box>
            <Box style={{ flex: 1, borderRadius: 10 }} p={mobile ? "md" : 0} bg={mobile ? "#f9f9f9" : "#fff"}>
                <AccountTypeTitle accountType="Twitter" />
                <Divider mt={10} variant="dashed" />
                <ServiceList services={["Twitter Ads", "Twitter Delegate Account"] as Array<TwitterServices>} />
            </Box>
        </Box>
    )
}
const Message = () => {
    const router = useRouter()
    const { message } = router.query
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (message) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [message])

    return (
        <Collapse in={open}>
            <Box p={"lg"} bg={"red"} className="flex aic jcsb">
                <div className='flex aic' style={{ gap: 10 }}>
                    <InfoCircle size={20} color="#fff" />
                    <Text c={"#fff"} fw={500}>{message}</Text>
                </div>
                <ActionIcon color={"white"} variant="subtle" onClick={() => setOpen(false)}>
                    <X />
                </ActionIcon>
            </Box>
        </Collapse>
    )
}

export const DefaultHeader = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
    const user = useContext(UserContext)
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const router = useRouter()
    useEffect(() => {
        router.events.on("routeChangeComplete", () => {
            closeDrawer()
        })
        router.events.on("hashChangeComplete", () => {
            closeDrawer()
        })
    }, [])

    const AuthModule = () => {
        if (isLoggedIn) {
            return (
                <div className='flex aic' style={{ gap: 10, flexWrap: "wrap" }}>
                    <Link href={"/logout"}>
                        <Button variant={"subtle"}>Logout</Button>
                    </Link>
                    <Link href={"/dashboard"}>
                        <Button id='go_to_dashboard_button' rightSection={<ArrowRight size={20} />}>Go to Dashboard</Button>
                    </Link>
                    <Link href={"/settings"}>
                        <Avatar src={user?.pfp}>{user?.name?.substring(0, 1)}</Avatar>
                    </Link>
                </div>
            )
        }
        return (
            <div className='flex aic' style={{ gap: 10 }}>
                <Link href={"/auth/login"}>
                    <Button id='login_button' variant={'subtle'}>Login</Button>
                </Link>
                <Link href={"/auth/signup?plan=Free"}>
                    <Button id='signup_button' rightSection={<ArrowRight size={20} />}>Get Started</Button>
                </Link>
            </div>
        )
    }
    return (
        <Box style={{ zIndex: 500, position: "fixed", width: "100%", backdropFilter: "blur(20px)", background: "rgba(255,255,255,.5)", top: 0 }}>
            <Message />
            <Box component={"header"} className="flex  jcc" px={25} h={80} style={{ borderBottom: "1px solid rgba(0,0,0,.05)" }}>
                <Group maw={1100} w="100%" justify={"space-between"} style={{ height: '100%' }}>
                    <div className='flex aic' style={{ gap: 5 }}>
                        <Logo width={175} href='/' />
                    </div>
                    <Group style={{ height: '100%' }} gap={0} className={"hiddenMobile"}>
                        <HoverCard width={"100%"} withArrow styles={{ dropdown: { width: "90%" } }}>
                            <HoverCard.Target>
                                <Link href="#features" className={"link"}>
                                    Features <ChevronDown size={18} style={{ marginLeft: 5 }} />
                                </Link>
                            </HoverCard.Target>
                            <HoverCard.Dropdown p="lg" bg={"#fff"} style={{ borderRadius: 20, boxShadow: "0px 0px 10px rgba(0,0,0,.1)" }}>
                                <IntegrationDropdown />
                                <Paper mt={15} p={"md"} bg="#f9f9f9" radius={"md"} className="flex aic jcsb">
                                    <div className='flex' style={{ gap: 10 }}>
                                        <Text fz={"xl"}>⏰</Text>
                                        <div>
                                            <Text fw={500}>Get started free </Text>
                                            <Text fz={"sm"} c="dimmed">Risk Free - No CC required</Text>
                                        </div>
                                    </div>
                                    <Link href={"/auth/signup?plan=Free"}>
                                        <Button variant={"default"}>Get Started</Button>
                                    </Link>
                                </Paper>
                            </HoverCard.Dropdown>
                        </HoverCard>
                        <Link href="/pricing" className={"link"}>
                            Pricing
                        </Link>
                        <Link href="/contact" className={"link"}>
                            Contact Us
                        </Link>
                        {/* <Link href="/blog" className={"link"}>
                            Our Blog
                        </Link> */}
                    </Group>

                    <div className='flex aic' style={{ gap: 20 }}>
                        <Group className={"hiddenMobile"}>
                            <AuthModule />
                        </Group>
                        <div className={"hiddenMobile"}>
                            {/* <CurrencyController /> */}

                        </div>
                        <Burger opened={drawerOpened} onClick={toggleDrawer} className={"hiddenDesktop"} />
                    </div>


                </Group>
            </Box>

            <Drawer
                opened={drawerOpened}
                onClose={closeDrawer}
                size="100%"
                padding="md"
                title={<Logo width={175} href='/' />}
                className={"hiddenDesktop"}
                zIndex={1000000}
            >
                <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md" className='flex fdc' style={{ alignItems: "flex-start" }}>
                    <Divider my="sm" mt={0} />
                    <div className="flex">
                        <Link href="/pricing" className={"link"}>
                            Pricing
                        </Link>
                    </div>
                    <div className="flex">
                        <Accordion w="100%" variant="default" styles={{ control: { padding: 0 }, item: { borderBottom: "none" }, chevron: { marginRight: 15 } }}>
                            <Accordion.Item value='Integrations'>
                                <Accordion.Control w={"100%"}>
                                    <Text className='link'>
                                        Features
                                    </Text>
                                </Accordion.Control>
                                <Accordion.Panel>
                                    <IntegrationDropdown />
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className="flex">
                        <Link href="/contact" className={"link"}>
                            Contact Us
                        </Link>
                    </div>

                    <Divider my="sm" />

                    <Box pb="xl" px="sm">
                        <AuthModule />
                        <div style={{ marginTop: 15 }} className="flex">
                            {/* <CurrencyController /> */}

                        </div>
                    </Box>

                </ScrollArea>
            </Drawer>
        </Box>
    );
}




export const DashboardHeader = () => {
    const user = useContext(UserContext)
    const router = useRouter()
    const [createLinkOpen, setCreateLinkOpen] = useState(false)
    const [createClientOpen, setCreateClientOpen] = useState(false)
    const spotlightActions: SpotlightActionData[] = [
        {
            id: 'dashboard',
            label: 'Dashboard',
            description: 'Go to the dashboard',
            onClick: () => router.push("/dashboard"),
            leftSection: <DeviceDesktopAnalytics size={25} />,
        },
        {
            id: 'link',
            label: 'Create a Link',
            description: 'Create a new access link',
            onClick: () => setCreateLinkOpen(true),
            leftSection: <Plus size={25} />,
        },
        {
            id: 'client',
            label: 'Create a Client',
            description: 'Create a new client',
            onClick: () => setCreateClientOpen(true),
            leftSection: <UserPlus size={25} />,
        },
        {
            id: 'branding',
            label: 'Edit Branding',
            description: 'Customize your branding options',
            onClick: () => router.push("/dashboard/branding"),
            leftSection: <Paint size={25} />,
        },
        {
            id: 'accounts',
            label: 'Manage Accounts',
            description: 'Manage your connected accounts',
            onClick: () => router.push("/dashboard/accounts"),
            leftSection: <LinkIcon size={25} />,
        },
        {
            id: 'settings',
            label: 'Settings',
            description: 'Edit Settings',
            onClick: () => router.push("/dashboard/settings/general"),
            leftSection: <Settings size={25} />,
        },
    ];


    return (
        <>
            <AccessLinkStateController open={createLinkOpen} setOpen={setCreateLinkOpen} />
            <ClientCreateStateController open={createClientOpen} setOpen={setCreateClientOpen} />
            <Box component={"header"} h={80} px={25} className="flex aic jcsb" style={{ borderBottom: "1px solid #e9ecef" }}>
                <Logo width={185} href='/dashboard' />
                <div className='flex aic' style={{ gap: 20 }}>
                    <div onClick={() => spotlight.open()} className="cp">
                        <TextInput
                            className='cp'
                            styles={{
                                input: {
                                    cursor: "pointer"
                                }
                            }}
                            leftSection={<Search size={18} />}
                            placeholder='Search'
                        />
                    </div>
                    <Spotlight
                        actions={spotlightActions}
                        searchProps={{
                            leftSection: <Search style={{ width: rem(20), height: rem(20) }} />,
                            placeholder: 'Search anything...',

                        }}
                        radius="md"
                    />
                    <Link href={"/settings"}>
                        <Avatar radius={100} alt={user?.name} src={user?.pfp}>{user?.name.substring(0, 1)}</Avatar>
                    </Link>
                </div>
            </Box>
        </>
    )
}


export const SettingsHeader = () => {
    const router = useRouter()
    return (
        <Box component={"header"} h={75} className="flex aic jcsb" p="sm" style={{ boxShadow: "0px 0px 10px rgba(0,0,0,.1)", background: "#fff", zIndex: 100, position: 'fixed', width: "100%", top: 0 }}>
            <div className='flex aic jcc' style={{ gap: 10 }}>
                <Logo width={200} />
                <Divider orientation="vertical" my={4} />
                <Badge variant={"light"} color="blue" radius={4} size="md">User Settings</Badge>
                {/* <Text>User Settings</Text> */}
            </div>
            <div className='flex aic' style={{ gap: 10 }}>
                <Button variant={"subtle"} leftSection={<Logout size={18} />} onClick={() => router.push("/logout")}>Log Out</Button>

                <Button variant={"default"} rightSection={<ArrowRight size={18} />} onClick={() => router.push("/dashboard")}>Back to Dashboard</Button>
            </div>
        </Box>
    )
}