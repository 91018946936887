import { Alert, Text } from '@mantine/core'
import React from 'react'
import { AlertCircle, Circle } from 'tabler-icons-react'

export default function Error({ children, mt }: { children: React.ReactNode, mt?: number }) {
    if (!children) return null
    return (
        <Alert mt={mt} color={"red"} styles={{ message: { marginTop: 0 } }} icon={<AlertCircle size={20} />}>
            {children}
        </Alert>
    )
}

export const ErrorList = ({ errors, mt }: { errors: string[] | null | undefined, mt?: number }) => {
    if (!errors || !errors.length) return null;
    return (
        <Error mt={mt}>
            <div>
                <Text fw={600} c="red">The following errors occured:</Text>
                <div className="flex fdc" style={{ gap: 10 }}>
                    {errors.map((e) => <div className="flex" style={{ gap: 8 }}>
                        <Circle fill="var(--mantine-color-red-5)" color="var(--mantine-color-red-5)" size={8} style={{ marginTop: 5, maxWidth: 8, minWidth: 8 }} />
                        <Text fz={"sm"} c="red">{e}</Text>
                    </div>)}
                </div>
            </div>
        </Error>
    )
}