import api from '@/utils/api'
import { AgencyContext } from '@/utils/context/Agency.context'
import { SubscriptionContext } from '@/utils/context/Subscription.context'
import { UserContext } from '@/utils/context/User.context'
import getPlan from '@/utils/data/getPlan'
import { ActionIcon, Button, Checkbox, Collapse, Divider, FileInput, HoverCard, Modal, Progress, Radio, RadioGroup, Text, TextInput, ThemeIcon, Tooltip } from '@mantine/core'
import { useDebouncedState, useDebouncedValue, useElementSize } from '@mantine/hooks'
import axios from 'axios'
import React, { ButtonHTMLAttributes, useContext, useEffect, useState } from 'react'
import { InfoCircle, UserCircle, X } from 'tabler-icons-react'
import { BillingPeriod, Client, ClientType, Plans, User } from '../../../../types/global'
import Error from '../Error'

type Stage = "details" | "success"
type FormProps = {
    currentValues?: Client,
    setValues?: (newInviteValues: Partial<Client>) => void,
    next?: () => void,
    previous?: () => void,
    close?: () => void
}

const stages = ["details", "success"] as Array<Stage>

export const ClientTypeSelector = ({ type, setType }: { type: ClientType, setType: (type: string | undefined | null) => void }) => {
    const agency = useContext(AgencyContext)
    const plan = getPlan(agency?.plan)
    return (
        <Radio.Group withAsterisk label="Type" value={type} onChange={setType} style={{ position: "relative" }}>
            <HoverCard withinPortal position="right" withArrow>
                <HoverCard.Target>
                    <ThemeIcon variant={"transparent"} style={{ position: "absolute", right: 0, top: 10 }}>
                        <InfoCircle size={20} />
                    </ThemeIcon>
                </HoverCard.Target>
                <HoverCard.Dropdown p="xs" maw={400} style={{ zIndex: 20000 }}>
                    <Text fz="sm" fw={500}>What are prospects and clients?</Text>
                    <Divider my={5} opacity={.5} />
                    <Text fw={500} fz="xs">Clients</Text>
                    <Text fz="xs" c="dimmed">Clients are people you are curently working for. You can request any level of access to their accounts</Text>
                    {/* @ts-ignore */}
                    <Text mt={2} fz="xs">Used this month: {agency?.monthlyAccessLinkCount}/{plan?.limits[agency.billingPeriod].accessLinks || "unlimited"}</Text>
                    <Text fw={500} mt={10} fz="xs">Prospects</Text>
                    <Text fz="xs" c="dimmed">Prospects are people who you are curently auditing. You'll only be able to request read access to their accounts. You can turn a prospect into a client at any time.</Text>
                    <Text mt={2} fz="xs">Used this month: {agency?.monthlyProspectAccessLinkCount}/{plan?.limits[agency?.billingPeriod as BillingPeriod].prospectAccessLinks || "unlimited"}</Text>
                </HoverCard.Dropdown>
            </HoverCard>
            <div className='flex' style={{ gap: 20 }}>
                <Radio id='client-type-client-radio' value={"Client" as ClientType} label="Client" description="" mt={10} />
                <Radio id='client-type-prospect-radio' value={"Prospect" as ClientType} label="Prospect" mt={10} />

            </div>
        </Radio.Group>
    )
}

export const ClientDetailsInput = (props: FormProps & { callback: (clientID: string, type: ClientType) => void, hideHeader?: boolean, defaultType?: ClientType }) => {
    const agency = useContext(AgencyContext)
    const plan = getPlan(agency?.plan as Plans)
    const [name, setName] = useState(props.currentValues?.name || "")
    const [company, setCompany] = useState(props.currentValues?.company || "")
    const [email, setEmail] = useState(props.currentValues?.email || "")
    const [website, setWebsite] = useState("")
    const [type, setType] = useState<ClientType>(props.defaultType || "Client")

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    return (
        <div style={{ paddingBottom: 10, minHeight: 100 }}>
            {props.hideHeader
                ? null
                : <>
                    <Text fw={500}>Create a new client</Text>
                    <Text fz={"sm"} c="dimmed">Enter your client's details below</Text>
                </>
            }


            <div style={{ marginTop: props.hideHeader ? 0 : 10, gap: 5 }} className="flex fdc">
                <TextInput id='form_create_client_input_name' value={name} onChange={(e) => setName(e.target.value)} required placeholder='Joe Smith' label="Name" />
                <TextInput id='form_create_client_input_company' value={company} onChange={(e) => setCompany(e.target.value)} required placeholder='Acme' label="Company" />
                <TextInput id='form_create_client_input_email' value={email} onChange={(e) => setEmail(e.target.value)} required placeholder='joesmith@email.com' label="Email Address" />
                <TextInput id='form_create_client_input_website' value={website} onChange={(e) => setWebsite(e.target.value)} placeholder='example.com' label="Website" />
                {/* @ts-ignore */}
                <ClientTypeSelector type={type} setType={setType} />
                {/* <Radio.Group withAsterisk label="Type" value={type} onChange={setType} style={{ position: "relative" }}>
                    <HoverCard withinPortal position="right" withArrow>
                        <HoverCard.Target>
                            <ThemeIcon variant={"transparent"} style={{ position: "absolute", right: 0, top: 10 }}>
                                <InfoCircle size={20} />
                            </ThemeIcon>
                        </HoverCard.Target>
                        <HoverCard.Dropdown p="xs" maw={400} style={{ zIndex: 20000 }}>
                            <Text fz="sm" fw={500}>What are prospects and clients?</Text>
                            <Divider my={5} opacity={.5} />
                            <Text fw={500} fz="xs">Clients</Text>
                            <Text fz="xs" c="dimmed">Clients are people you are curently working for. You can request any level of access to their accounts</Text>
                            <Text mt={2} fz="xs">Used this month: {user?.monthlyAccessLinkCount}/{plan?.limits.accessLinks || "unlimited"}</Text>
                            <Text fw={500} mt={10} fz="xs">Prospects</Text>
                            <Text fz="xs" c="dimmed">Prospects are people who you are curently auditing. You'll only be able to request read access to their accounts. You can turn a prospect into a client at any time.</Text>
                            <Text mt={2} fz="xs">Used this month: {user?.monthlyProspectAccessLinkCount}/{plan?.limits.prospectAccessLinks || "unlimited"}</Text>
                        </HoverCard.Dropdown>
                    </HoverCard>
                    <Radio value={"Client" as ClientType} label="Client" description="" mt={10} />
                    <Radio value={"Prospect" as ClientType} label="Prospect" description="You'll only be able to request read only access" mt={10} />
                </Radio.Group> */}
            </div>
            <Button id='form_create_client_input_submit' loading={loading} disabled={!name || !company || !email} mt={20} fullWidth onClick={() => {
                setLoading(true)
                const newFormData = new FormData()
                newFormData.append("name", name)
                newFormData.append("email", email)
                newFormData.append("company", company)
                newFormData.append("website", website)
                newFormData.append("type", type)
                newFormData.append("useFavicon", "true")
                newFormData.append("agency", agency.id)
                api("/clients/create", newFormData).then((res) => {
                    setLoading(false)

                    if (res.error) {
                        return setError(res.msg)
                    }
                    props.callback(res.data, type)
                    // props.close()
                })
            }}>Create</Button>
            <Error mt={10}>{error}</Error>
        </div>
    )
}

const StageController = ({ setOpen, defaultType }: { setOpen: (open: boolean) => void, defaultType?: ClientType }) => {
    const { ref, height } = useElementSize();

    const [stage, setStage] = useState<Stage>("details")
    const [details, setDetails] = useState<Client | {}>({})

    const incrementStage = () => {
        setStage(stages[stages.indexOf(stage) + 1])
    }
    const decrementStage = () => {
        setStage(stages[stages.indexOf(stage) - 1])
    }

    return (
        <div style={{ transition: "all .2s", borderRadius: 10, overflow: "hidden", height: height, position: "relative", background: "white", margin: "0 auto" }}>
            <ActionIcon onClick={() => setOpen(false)} style={{ position: "absolute", top: 25, right: 25 }}>
                <X size={20} />
            </ActionIcon>
            <Progress value={((stages.indexOf(stage) + 1) / stages.length) * 100} style={{ borderRadius: 0 }} styles={{
                root: { borderRadius: 0 }, section: {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0
                }
            }} />
            <div ref={ref} style={{ width: 450 }}>
                <div style={{ padding: 20, height: "fit-content" }}>
                    {stage === 'details' && <ClientDetailsInput
                        defaultType={defaultType}
                        close={() => setOpen(false)}
                        currentValues={details as Client}
                        setValues={setDetails}
                        next={incrementStage}
                        previous={decrementStage}
                        callback={() => setOpen(false)}
                    />}
                </div>
            </div>
        </div>
    )
}
export const ClientCreateStateController = ({ open, setOpen, defaultType }: { open: boolean, setOpen: (val: boolean) => void, defaultType?: ClientType }) => {
    return (
        <Modal
            opened={open}
            centered
            onClose={() => {
                setOpen(false)
            }}
            withCloseButton={false}
            padding={0}
            zIndex={2000}
            size="auto"
            styles={{
                content: {
                    width: '100%',
                    maxWidth: 650,
                    height: "100%",
                    background: "transparent",
                    boxShadow: "none"
                },
                body: {
                    height: "100%",
                    display: "flex",
                    maxWidth: 650,
                    alignItems: "center",
                    margin: "0 auto"
                }
            }}
        >
            <StageController setOpen={setOpen} />
        </Modal >
    )
}
export default function ClientCreateModal({ target, defaultType }: { target: React.ReactElement<{}>, defaultType?: ClientType }) {
    const subscriptionActive = useContext(SubscriptionContext)
    const [open, setOpen] = useState(false)
    const agency = useContext(AgencyContext)

    return (
        <>
            <div>
                {React.cloneElement(target, {
                    onClick: () => setOpen(true),
                    disabled: !subscriptionActive && agency?.subscriptionExpires as number < Date.now()
                } as ButtonHTMLAttributes<{}>)}

            </div>
            <Modal
                opened={open}
                centered
                onClose={() => {
                    setOpen(false)
                }}
                withCloseButton={false}
                padding={0}
                zIndex={2000}
                size="auto"
                styles={{
                    content: {
                        width: '100%',
                        maxWidth: 650,
                        height: "100%",
                        background: "transparent",
                        boxShadow: "none"
                    },
                    body: {
                        height: "100%",
                        display: "flex",
                        maxWidth: 650,
                        alignItems: "center",
                        margin: "0 auto"
                    }
                }}
            >
                <StageController defaultType={defaultType} setOpen={setOpen} />
            </Modal >
        </>
    )
}

