import { AgencyContext } from '@/utils/context/Agency.context'
import { ClientsContext } from '@/utils/context/Clients.context'
import { firestore } from '@/utils/firebase'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { Client } from '../../../types/global'

export default function ClientsProvider(props: PropsWithChildren) {
    const agency = useContext(AgencyContext)
    const [clients, setClients] = useState<Array<Client>>([])
    useEffect(() => {
        if (!agency) {
            return
        }
        const unsub = onSnapshot(query(collection(firestore, "clients"), where("owner", "==", agency?.id)), (snaps) => {
            const newClients = new Array() as Array<Client>
            snaps.forEach(snap => newClients.push({ ...snap.data() as Client, id: snap.id }))
            setClients(newClients)
        })
        return () => unsub()
    }, [agency])

    return (
        <ClientsContext.Provider value={clients}>
            {props.children}
        </ClientsContext.Provider>
    )
}
