import api from "@/utils/api"
import { PlatformsContext } from "@/utils/context/Platforms.context"
import { ThemeContext } from "@/utils/context/Theme.context"
import manualInstructions, { InstructionProps } from "@/static/manualInstructions"
import { roleValueToLabel } from "@/static/roles"
import { getGrantedStatus } from "@/utils/helpers/useGranted"
import { Box, Button, Collapse, Divider, Paper, Select, Skeleton, Text, ThemeIcon, Image as MantineImage, Loader, useMantineTheme, HoverCard, Tooltip, ActionIcon, Transition } from "@mantine/core"
import { useHover, useMediaQuery } from "@mantine/hooks"
import Image from "next/image"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { Bulb, Check, Circle, CircleCheck, CircleNumber1, CircleNumber2, CircleNumber3, CircleNumber4, CircleNumber5, CircleNumber6, CircleNumber7, CircleNumber8, CircleNumber9, File, Hammer, InfoCircle, InfoSquare, Number1, Number2, Number3, Number4, Number5, Number6, Number7, Number8, Number9, PlayerSkipForward, RobotOff, Settings, SettingsAutomation } from "tabler-icons-react"
import { AccountTypes, AnyService, BaseServiceConfig, GoogleServices, Invite, MetaServices, PinterestServices, RequestedServices, ServiceConfig, ShopifyServices, Theme, TwitterServices } from "../../../../types/global"
import Error, { ErrorList } from "../Error"
import { InlinePageLink } from "../InlineLink"
import ManualAccessGrantsListener from "./ManualAccessGrantsListener"
import { AmazonAdsInstructions, GoogleSearchConsoleInstructions, HubSpotPartnerInstructions, InstagramInstructions, ManualAccessInstructionsRendererProps, MetaAdsInstructions, PinterestAdsInstructions, ProfitMetricsPartnerInstructions, ShopifyCollaboratorInstructions, SnapchatAdsInstructions, SnapchatBusinessInstructions, TwitterAdsInstructions, TwitterDelagateAccountInstructions, YouTubeStudioInstructions } from "./ManualInstructions"
import { GrantedChip, Selector } from "./ServiceConfigSelector"
import { ManualGrantsContext } from "@/utils/context/ManualGrants.context"
import { InviteContext } from "@/utils/context/Invite.context"
import PlatformAuthButton from "./PlatformAuthButton"
import { useGrantStatus } from "@/hooks/useGrantStatus"

export type GrantsStatus = {
    [key in AnyService]?: {
        granted: boolean
    }
}


const ServiceGrant = ({ isManual, isGranted, loading, serviceConfigs, service, account, id, isLogged, apiRoutes, isPreview, updateServiceConfig, updateGrantsStatus }: {
    isManual: boolean,
    isGranted: boolean,
    loading: boolean,
    serviceConfigs: BaseServiceConfig,
    service: AnyService,
    account: AccountTypes,
    id: string,
    isLogged: boolean,
    apiRoutes: { [key in string]: string },
    updateServiceConfig: (newServiceConfig: any) => void,
    updateGrantsStatus: (newGrants: GrantsStatus) => void,
    isPreview: boolean
}) => {
    const invite = useContext(InviteContext)
    const theme = useContext(ThemeContext)
    const [tutExpanded, setTutExpanded] = useState(isGranted || isPreview ? false : true)
    const vertical = useMediaQuery("(max-width: 460px)")
    const platforms = useContext(PlatformsContext)
    const { hovered, ref } = useHover()
    useEffect(() => {
        if (isGranted) {
            setTutExpanded(false)
        }
    }, [isGranted])

    let assetSelector = null
    if (apiRoutes && !isPreview) {
        assetSelector = <Selector
            key={service}
            service={service}
            apiRoute={apiRoutes[service]}
            serviceConfig={serviceConfigs}
            setServiceConfigs={updateServiceConfig}
            inviteID={id}
            businessName={invite.creds ? (invite.creds[account]?.business?.name || "") : ""}
        />
    }
    const manualAccessProps = {
        isLogged: isLogged,
        serviceConfigs: serviceConfigs as ServiceConfig<AnyService>,
        updateGrantsStatus,
        updateServiceConfig,
        isGranted,
        assetSelector: isLogged && !isGranted ? assetSelector : null
    } as ManualAccessInstructionsRendererProps

    return (
        <Box ref={ref} style={{ borderRadius: 10, overflow: "hidden" }}>
            {isManual && !isPreview
                ? <Collapse in={isGranted ? false : (!!(serviceConfigs[service]?.length))}>
                    <ManualAccessGrantsListener setParentGranted={(val) => {
                        if (!updateGrantsStatus) return
                        updateGrantsStatus({
                            [service]: {
                                granted: val
                            }
                        })
                    }} readOnlyGranted={isGranted} service={service as AnyService} accountType={account} resources={serviceConfigs[service]} inviteID={id} />
                </Collapse>
                : null
            }

            <div key={service} className={`flex ${vertical ? "" : ""} jcsb`} style={{ width: "100%", flexDirection: vertical ? "column" : "row", padding: isManual ? 0 : 0, paddingBottom: isGranted && isManual ? 12 : 0 }}>
                <Box h={"fit-content"} className='flex' style={{ gap: 7 }}>
                    {loading && !isGranted
                        ? <div className="flex aic jcc" style={{ width: 25, height: 25 }}>
                            <Loader size={"xs"} type="oval" color={theme.theme === "dark" ? "#fff" : undefined} />
                        </div>
                        : <Box bg={"#fff"} className='flex aic jcc' style={{ borderRadius: 100, width: 25, height: 25, overflow: "hidden" }}>
                            {/* <Image width={25} height={25} alt={`${service} logo`} style={{ borderRadius: 50, objectFit: "contain", backgroundColor: "#f1f1f1", padding: 2 }} src={`/images/logos/${service.toLowerCase().replaceAll(" ", "_")}.png`} /> */}
                            <Image width={18} height={18} alt={`${service} logo`} style={{ borderRadius: 0, objectFit: "contain", backgroundColor: "#fff", padding: 0 }} src={`/images/logos/${service.toLowerCase().replaceAll(" ", "_")}.png`} />
                        </Box>
                    }
                    <div>
                        {!isPreview && invite.requestedAccounts[account][service].optional
                            ? <Box w="fit-content" bg={"orange.1"} style={{ borderRadius: 4, gap: 3 }} className="flex aic" px={4} py={2}>
                                <InfoSquare strokeWidth={2.5} size={12} color="var(--mantine-color-orange-6)" />
                                <Text lh={0.8} fz={10} c="orange.6" fw={700}>Optional</Text>
                            </Box>
                            : null
                        }
                        <Text fw={400} fz={"sm"}>{service}</Text>
                        {service === "Google Ads" && !isPreview && !invite.requestedAccounts[account][service].accessLevel // This indicates the agency has requested MCC access to the users account
                            ? <HoverCard position="left" withArrow>
                                <HoverCard.Target>
                                    <Box mt={1.5} className="flex aic" style={{ gap: 5 }}>
                                        <InfoCircle size={12} color={"var(--mantine-color-dimmed)"} />
                                        <Text lh={1} fz="xs" c="dimmed">Manager Access</Text>
                                    </Box>
                                </HoverCard.Target>
                                <HoverCard.Dropdown maw={300}>
                                    <Text fz="sm">Your account will be added to {invite.agency.name}'s Google Ads MCC account. <InlinePageLink newTab href="https://ads.google.com/intl/en_uk/home/tools/manager-accounts/">Learn More</InlinePageLink></Text>
                                </HoverCard.Dropdown>
                            </HoverCard>
                            : <Text fz={"xs"} c="dimmed">{!isPreview ? roleValueToLabel(service, invite.requestedAccounts[account][service].accessLevel, platforms) : ""}</Text>
                        }

                    </div>
                    {/* <Transition transition={"slide-right"} mounted={hovered}>
                        {(styles) => (
                            <div style={{ ...styles, gap: 10, marginLeft: 5 }} className="flex aic">
                                <Divider orientation="vertical" my={7} />
                                <Box className="flex aic" style={{ gap: 5 }}>
                                    <Tooltip fz="sm" label="Mark as granted">
                                        <ActionIcon size={"sm"}>
                                            <CircleCheck size={16} />
                                        </ActionIcon>

                                    </Tooltip>
                                    <ActionIcon size={"sm"}>
                                        <PlayerSkipForward size={16} />
                                    </ActionIcon>
                                </Box>

                            </div>

                        )}
                    </Transition> */}
                </Box>
                {
                    isGranted && !isPreview
                        ? <GrantedChip skipped={invite.requestedAccounts[account][service].skipped} />
                        : null
                }
                {
                    isLogged && !isGranted && !isManual // For manual services, we'll show the selector within the guide
                        // @ts-ignore
                        ? <>{assetSelector}</>
                        : null
                }
            </div>
            {isManual && !isGranted && <Divider variant={"dashed"} my={12} />}
            {isManual && !isGranted && <Box className="flex aic" style={{ gap: 10 }} px={12}>
                <Bulb size={20} />
                <Box >
                    <Text fz="sm" fw={600}>Guide</Text>
                    <Text c="dimmed" lh={1} fz="xs">Follow this guide to grant access</Text>
                </Box>
                <Tooltip disabled={!isPreview} withArrow label="Guides are dynamic so can't be displayed in preview mode">
                    <Button disabled={isPreview} style={{ marginLeft: "auto" }} size={"compact-sm"} variant={"subtle"} onClick={() => setTutExpanded(!tutExpanded)} color={theme.color === "dark" && theme.theme === "dark" ? "#fff" : (theme.color || "dark")}>{tutExpanded ? "Hide Guide" : "Show Guide"}</Button>
                </Tooltip>
            </Box>}

            <Collapse in={(!!isManual)}>
                <div style={{ padding: 0, paddingTop: 0 }}>
                    <Collapse in={tutExpanded} mt={12}>
                        {!isPreview

                            ? < Box style={{ borderRadius: 10 }} bg={isManual && !isGranted ? (theme.theme === "dark" ? "#1A202C" : "gray.0") : "none"} pt={0} p={isManual && !isGranted ? 12 : 0}>
                                {service as GoogleServices === "Google Search Console"
                                    ? <GoogleSearchConsoleInstructions
                                        {...manualAccessProps}
                                        oAuthProviderButton={<PlatformAuthButton account="Google" />}
                                    />
                                    : null
                                }
                                {service as GoogleServices === "YouTube Studio"
                                    ? <YouTubeStudioInstructions
                                        {...manualAccessProps}
                                        oAuthProviderButton={<PlatformAuthButton account="Google" />}
                                    />
                                    : null
                                }
                                {service as ShopifyServices === "Shopify Store Collaborator"
                                    ? <ShopifyCollaboratorInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service as TwitterServices === "Twitter Delegate Account"
                                    ? <TwitterDelagateAccountInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service as TwitterServices === "Twitter Ads"
                                    ? <TwitterAdsInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service as MetaServices === "Instagram"
                                    ? <InstagramInstructions
                                        {...manualAccessProps}
                                        oAuthProviderButton={<PlatformAuthButton account="Meta" />}
                                    />
                                    : null
                                }
                                {service as PinterestServices === "Pinterest Ads"
                                    ? <PinterestAdsInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "Meta Ads"
                                    ? <MetaAdsInstructions
                                        {...manualAccessProps}
                                        oAuthProviderButton={<PlatformAuthButton account="Meta" />}
                                    />
                                    : null
                                }
                                {service === "Amazon Ads"
                                    ? <AmazonAdsInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "Snapchat Ads"
                                    ? <SnapchatAdsInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "Snapchat Business"
                                    ? <SnapchatBusinessInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "HubSpot Partner"
                                    ? <HubSpotPartnerInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                                {service === "ProfitMetrics Partner"
                                    ? <ProfitMetricsPartnerInstructions
                                        {...manualAccessProps}
                                    />
                                    : null
                                }
                            </Box>
                            : null
                        }

                    </Collapse>
                </div>
            </Collapse>

        </Box >
    )
}

const AccountsRenderer = ({ services, type, account, grantsStatus, updateServiceConfig, isLogged, isPreview, id, loading, serviceConfigs, apiRoutes, updateGrantsStatus }: {
    services: Record<AnyService, any>,
    type: "manual" | "auto",
    account: AccountTypes,
    id: string,
    isLogged: boolean,
    apiRoutes: { [key in string]: string },
    updateServiceConfig: (newServiceConfig: any) => void,
    updateGrantsStatus: (newGrants: GrantsStatus) => void,
    grantsStatus: GrantsStatus,
    loading: boolean,
    serviceConfigs: BaseServiceConfig,
    isPreview: boolean
}) => {
    console.log(services)
    const platforms = useContext(PlatformsContext)
    return (
        <>
            {Object.keys(services).sort().map((service: unknown, i) => {
                const isManual = !!platforms?.find((p) => p.platform === account)?.services?.find((s) => s.name === service)?.manual
                const isGranted = !!grantsStatus[service as AnyService]?.granted
                if (type === "auto" && isManual) return null
                if (type === "manual" && !isManual) return null

                // Snapchat Ads includes access to Snapchat Business, so we won't render Snapchat Business if Ads is included in the request 
                if ((service as AnyService) === "Snapchat Business" && Object.keys(services).includes("Snapchat Ads")) return null

                return (
                    <ServiceGrant
                        updateServiceConfig={updateServiceConfig}
                        account={account}
                        // details={details}
                        isGranted={isGranted}
                        isManual={isManual}
                        isLogged={isLogged}
                        id={id}
                        loading={loading}
                        service={service as AnyService}
                        serviceConfigs={serviceConfigs}
                        apiRoutes={apiRoutes}
                        updateGrantsStatus={updateGrantsStatus}
                        isPreview={isPreview}
                    />
                )
            })}
        </>
    )
}

export const ServiceGrantsContainer = ({ services, account, isLogged, id, grantURI, apiRoutes, setGranted, isPreview }: {
    services: RequestedServices,
    account: AccountTypes,
    isLogged: boolean,
    id: string,
    grantURI: string,
    apiRoutes: { [key in string]: string },
    setGranted: (granted: boolean) => void,
    isPreview: boolean
}) => {
    const invite = useContext(InviteContext)
    const [serviceConfigs, setServiceConfigs] = useState<BaseServiceConfig>({})
    const [loading, setLoading] = useState(false)
    const { grantsStatus, setGrantsStatus } = useGrantStatus(invite, account, isPreview)
    // const [grantsStatus, setGrantsStatus] = useState<GrantsStatus | undefined>(undefined)
    const [error, setError] = useState("")
    const [errorList, setErrorList] = useState<Array<string>>([])
    const manualGrants = useContext(ManualGrantsContext)

    const theme = useContext(ThemeContext)

    const platforms = useContext(PlatformsContext)


    useEffect(() => {
        setError("")
        setErrorList([])
    }, [account])

    // useEffect(() => {
    //     const newGrantsStatus = {} as GrantsStatus
    //     if (isPreview) return setGrantsStatus({});
    //     Object.keys(invite.requestedAccounts).map(ra => {
    //         if (ra !== account) return
    //         Object.keys(invite.requestedAccounts[ra as AccountTypes]).map((service) => {
    //             newGrantsStatus[service as AnyService] = {
    //                 granted: !!invite.requestedAccounts[ra as AccountTypes][service as AnyService].granted
    //             }
    //         })
    //     })
    //     setGrantsStatus(newGrantsStatus)
    // }, [invite, account])


    const updateServiceConfig = (newConfig: BaseServiceConfig) => {
        setServiceConfigs((prev) => ({ ...prev, ...newConfig }))
    }

    const isFullyGranted = () => {
        return grantsStatus === undefined ? false : !Object.keys(grantsStatus).filter((service) => !grantsStatus[service as MetaServices | GoogleServices]?.granted).length
    }

    const isAutomaticFullyGranted = useMemo(() => {
        return grantsStatus === undefined ? false : !(Object.keys(grantsStatus).filter((service) => !manualGrants.includes(service as MetaServices | GoogleServices)).filter((service) => !grantsStatus[service as MetaServices | GoogleServices]?.granted).length)
    }, [grantsStatus, manualGrants])

    const includesManualGrants = (services: RequestedServices) => {
        return !!Object.keys(services).filter((service) => manualGrants.includes(service as any)).length
    }

    const includesAutomaticGrants = (services: RequestedServices) => {
        return Object.keys(services).filter((service) => manualGrants.includes(service as any)).length < Object.keys(services).length
    }

    useEffect(() => {
        setGranted(isFullyGranted())
    }, [grantsStatus])

    console.log("Inc man grants:", includesManualGrants(services), services, manualGrants)

    if (!grantsStatus) {
        if (!services) return null;
        console.log("PASSED SERVICES", Object.keys(services).length)
        return <div className='flex fdc' style={{ gap: 10, marginTop: 15 }}>
            {new Array(Object.keys(services).length).fill(0).map(() => {
                return <Skeleton style={{ borderRadius: 10, width: "100%", height: 64 }} />
            })}
        </div>
    }

    return (
        <div className='flex fdc' style={{ gap: 15, marginTop: 15 }}>
            {/* @ts-ignore */}
            {/* Automatic Grants */}

            {includesAutomaticGrants(services)
                ? <Box>
                    {includesManualGrants(services)
                        ? <div style={{ marginBottom: 10 }}>
                            <Text fz={"md"} fw={500}>Automatic</Text>
                            <Text fz={"xs"} c="dimmed">These services can be granted access to automatically</Text>
                        </div>
                        : null
                    }
                    <Box bg={theme.theme === "dark" ? "#1A202C" : "gray.0"} style={{ borderRadius: 10, gap: 15, overflow: "hidden" }} className="flex fdc" p="sm">
                        <AccountsRenderer
                            isPreview={isPreview}
                            updateGrantsStatus={(newGrants) => {
                                setGrantsStatus((prev) => ({
                                    ...prev,
                                    ...newGrants
                                }))
                            }}
                            updateServiceConfig={updateServiceConfig}
                            account={account}
                            // details={details}
                            isLogged={isLogged}
                            id={id}
                            loading={loading}
                            serviceConfigs={serviceConfigs}
                            apiRoutes={apiRoutes}
                            grantsStatus={grantsStatus}
                            services={services}
                            type="auto"
                        />
                        {isPreview || (isLogged && !isAutomaticFullyGranted)
                            // @ts-ignore
                            ? <Button fullWidth color={theme.color || "dark"} loading={loading} disabled={isPreview ||
                                // !!(Object.keys(details.requestedAccounts[account]).filter((acc: string) => {
                                // if (details.requestedAccounts[account][acc].granted || !!platforms?.find((p) => p.platform === account)?.services?.find((s) => s.name === acc)?.manual) {
                                //     return false
                                // }
                                // return !(!!serviceConfigs[acc])
                                // }).length)
                                false
                            } onClick={() => {
                                setLoading(true)
                                setError("")
                                setErrorList([])

                                const newServiceCongigs: BaseServiceConfig = {};

                                (Object.keys(serviceConfigs) as Array<AnyService>).map((service: AnyService) => {
                                    if (invite.requestedAccounts[account][service]?.granted || !!platforms?.find((p) => p.platform === account)?.services?.find((s) => s.name === service)?.manual) {
                                        return
                                    }
                                    newServiceCongigs[service] = serviceConfigs[service]
                                });

                                api(grantURI, {
                                    serviceConfig: newServiceCongigs,
                                    inviteID: id
                                }).then((res) => {
                                    if (res.error) {
                                        setLoading(false)
                                        return setError(res.msg)
                                    }

                                    const responseData = res.data as {
                                        grantsStatus: GrantsStatus,
                                        errors: Array<string>
                                    }
                                    setLoading(false)
                                    if (responseData.errors?.length) {
                                        setErrorList(responseData.errors)
                                    }
                                })
                            }}>Grant Access</Button>
                            : null
                        }
                    </Box>
                </Box>
                : null
            }

            {error
                ? <Error>{error}</Error>
                : <ErrorList errors={errorList} />
            }
            {/* Manual Grants */}
            {includesManualGrants(services)
                ? <>
                    {/* <div style={{ marginTop: includesAutomaticGrants(services) ? 10 : 0 }}>
                        <Text fz={"md"} fw={500}>Manual</Text>
                        <Text fz={"xs"} c="dimmed">These services must be granted access to manually</Text>
                    </div> */}
                    <AccountsRenderer
                        isPreview={isPreview}
                        updateServiceConfig={updateServiceConfig}
                        account={account}
                        // details={details}
                        isLogged={isLogged}
                        id={id}
                        loading={false}
                        serviceConfigs={serviceConfigs}
                        apiRoutes={apiRoutes}
                        grantsStatus={grantsStatus}
                        services={services}
                        updateGrantsStatus={(newGrants) => {
                            setGrantsStatus((prev) => ({
                                ...prev,
                                ...newGrants
                            }))
                        }}
                        type="manual"
                    />
                </>
                : null
            }
        </div>
    )
}