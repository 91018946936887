import axios, { AxiosStatic, Method } from 'axios';
import { APIResponse } from '../../../types/global';
import { auth } from './firebase';

export const API_BASE_URL =
    process.env.NEXT_PUBLIC_VERCEL_ENV === "preview" ? "https://staging-dot-agencyaccess-bff0f.uc.r.appspot.com" : (process.env.NODE_ENV === 'production' || (typeof window !== "undefined" ? !!window.Cypress : false))
        ? 'https://agencyaccess-bff0f.uc.r.appspot.com'
        : 'http://localhost:8080';

const api: (path: string, body?: object | FormData, authenticate?: boolean, method?: "get" | "post") => Promise<APIResponse> = async (path: string, body?: object | FormData, authenticate?: boolean, method?: "get" | "post") => {
    const url = `${API_BASE_URL}${path}`;

    // console.log(`${API_BASE_URL}`)

    let xAuth = undefined
    if ((authenticate !== false) && auth.currentUser) {
        const token = await auth.currentUser.getIdToken(true)
        xAuth = token
    }
    // @ts-ignore
    return axios[method || "post"](url, (!(body instanceof FormData) ? { ...body, agency: window?.localStorage?.getItem("agency") as string } : (!body ? (authenticate !== false ? { agency: window.localStorage.getItem("agency") } : {}) : body)), {
        headers: {
            "x-auth": xAuth
        } as any,
    }).then((response) => {
        return response.data as APIResponse;
    }).catch((err) => {
        return {
            error: true,
            msg: `Something went wrong on our end ${err.response?.status ? `(${err.response?.status})` : ""}`,
            data: null
        } as APIResponse
    });

    // return response.data;
};

export default api