import { LoadingScreen } from '@/components/LoadingScreen'
import { ThemeContext } from '@/utils/context/Theme.context'
import { firestore } from '@/utils/firebase'
import { doc, getDoc } from 'firebase/firestore'
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { Theme } from '../../../types/global'
import { useRouter } from 'next/router'
import { InviteContext } from '@/utils/context/Invite.context'

export default function ThemeProvider({ children, theme: overwriteTheme, agencyID: overwriteAgencyID }: { agencyID?: string, theme?: Theme } & PropsWithChildren) {
    const invite = useContext(InviteContext)
    const agencyID = overwriteAgencyID || invite?.agency?.id
    const [theme, setTheme] = useState<Theme | undefined>(overwriteTheme || undefined)
    const [isPreview, setIsPreview] = useState(false)
    const router = useRouter()
    useEffect(() => {
        if (router.query.preview) {
            setIsPreview(true)
        }
    }, [router])

    useEffect(() => {
        if (isPreview && router.query.aid) {
            getDoc(doc(firestore, "themes", router.query.aid as string)).then(snap => setTheme({ ...snap.data() }))
            return
        }
        if (!agencyID) return;
        getDoc(doc(firestore, "themes", agencyID)).then(snap => setTheme({ ...snap.data() }))
    }, [agencyID, isPreview, router])

    useEffect(() => {
        if (!overwriteTheme) return;
        setTheme(overwriteTheme)
    }, [overwriteTheme])
    console.log(theme, overwriteTheme)

    if (router.query.theme && !overwriteTheme) {
        console.log("Rending raw children", router.query.theme, overwriteTheme)
        return <>{children}</>
    }

    if (!theme) {
        return <LoadingScreen />
    }
    return (
        <ThemeContext.Provider value={theme}>
            {children}
        </ThemeContext.Provider>
    )
}
